import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import brandLogo from '../assets/images/BrandLogo.svg';
import MegatronLogo from '../assets/images/MegatronLogo.svg';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { constants, AppDefaults, Utils } from '../helpers';
import { useSearchParams } from 'react-router-dom';
import {
  HiOutlineExclamationCircle,
} from 'react-icons/hi';
import './SignIn.scss';
import { Header, SiteModal } from '../components/common';
import { useAccountStatus } from '../store/AccountStatusStore';
import AccountNotEnabledModal from './AccountNotEnabledModal';
import AccountNotVerifiedModal from './AccountNotVerifiedModal';
import { useOrganizations } from '../store/OrganizationsStore';
import { useTranslation } from 'react-i18next';
import { useNotificationStore } from '../store/CreateNotificationStore';
import { useCustomerOrgLocations } from '../store/CustomerOrgLocationsStore';
import { useLoggedInUserData } from '../store/LoggedInAccountStore';
import axios from 'axios';
import { setOpenSourceConditions } from '../store/reducers/AccountReducer';
import { useDispatch } from 'react-redux';
import { resetPlatformInfo } from '../store/reducers/StreamingReducer';
import { resetCustomerOrgData } from '../store/OrganizationsStoreIDB';
import { resetSelfAccountData } from '../store/reducers/OrganizationsReducer';

function SignIn() {
  const privacyPolicyUrl = 'https://policies.hanwhavision.cloud/privacy/index.html';
  const termsAndCondtionUrl = 'https://policies.hanwhavision.cloud/terms-of-service/index.html';
  const [privacyPolicy, setPrivacyPolicy] = useState(privacyPolicyUrl);
  const [termServices, setTermServices] = useState(termsAndCondtionUrl);
  const dispatch = useDispatch(); 
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const sessionexpire = searchParams.get('sessionexpire');
  const { keycloak } = useKeycloak();
  const location = useLocation();
  if(location.search.includes('?redirect_app_url')){
    const url = location.search.replace("?redirect_app_url=",'');
    sessionStorage.setItem('redirectUrl',url);
  }
  const tenantID =  process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON ? 'hva' : 'dc';
  const appID = 'portal';
  const [show, setShow] = useState(true);
  const deviceId = searchParams.get('deviceId');
  const orgId = searchParams.get('orgId');
  const nType = searchParams.get('nType');
  const eventTimestamp = searchParams.get('eventTimestamp');
  const token = localStorage.getItem('vmsAuthToken');
  const notificationData = {
    deviceId: deviceId,
    orgId: orgId,
    nType: nType,
    eventTimestamp: eventTimestamp,
  };
  const setUserPushData = useOrganizations((state) => state.setUserPushData);

  // const resetCustomerOrgData = useOrganizations(
  //   (state) => state.resetCustomerOrgData
  // );
  const resetNotificationData = useNotificationStore(
    (state) => state.resetStepData
  );
  const resetCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.resetCustomerOrgLocations
  );
  const resetLoggedInUserData = useLoggedInUserData(
    (state) => state.resetLoggedInUserData
  );
  const setLoggedInUserData = useLoggedInUserData(
    (state) => state.setLoggedInUserData
  );
  
  useEffect(()=>{
    if (token !== 'undefined'){
      getPolicies();
    }else{
      delete axios.defaults.headers.common['Authorization'];
      getPolicies();
    }
  },[]);

  const getPolicies = async () => {
      const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}partner/policies`,
            {
              headers: {
                'X-Tenant-Id': tenantID,
                'X-App-Id': appID,
              },
            }
          );
  
          const responseData = response?.data;
          if (responseData?.meta?.code === 200) {
            dispatch(setOpenSourceConditions(responseData.data));
            setPrivacyPolicy(responseData.data.ppLink);
            setTermServices(responseData.data.tosLink);
          }

      };

  const {
    getIsActNotEnabled,
    getIsActNotActivated,
    setIsActNotActivated,
    setIsActNotEnabled,
  } = useAccountStatus();
  const isActNotEnabled = getIsActNotEnabled();
  const isActNotActivated = getIsActNotActivated();
  const tokenWeb = localStorage.getItem('vmsAuthToken');
  /* If logged in and someone tries to hit signin url redirect to the Dashboard. */
  if (tokenWeb !== null && tokenWeb !== 'undefined') {
    if (notificationData && nType) {
        setUserPushData(notificationData);
    } else {
        setUserPushData({});
    }
    return <Navigate to="/customers/manage.html" push={true} />;
  } else if (notificationData && nType) {
    setUserPushData(notificationData);
  } else {
    setUserPushData({});
  }

  const handleLoginButtonClick = async () => {
    localStorage.removeItem('tokenSend');
    localStorage.setItem('authenticated', false);
    localStorage.setItem('expired-license-action', false);
    localStorage.setItem('vmsAuthToken', null);
    localStorage.setItem('isSessionApiCall', false);
    dispatch(resetSelfAccountData());
    setLoggedInUserData([])
    await resetCustomerOrgData();
    await resetNotificationData();
    await resetCustomerOrgLocations();
    await resetLoggedInUserData();
    await dispatch(resetPlatformInfo());
    loginUser()
  };

  const loginUser = () => {
    keycloak.login({
      redirectUri: process.env.REACT_APP_KEYCLOAK_LOGIN_REDIRECT_URL,
    });
  };

  // INVALIDATE API INTEGRATION
  const invalidateSessionApi = () => {
    const reqBody = {
      refresh_token: keycloak?.refreshToken,
    };
    axios
      .post('/user/token/invalidate/app', reqBody, Utils.requestHeader())
      .then((res) => {
        if (res?.data?.meta?.code === 200) {
          keycloak.logout({
            redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL,
          });
        }
      });
  };


  return (
    <div className="App">
        {tokenWeb != null && tokenWeb != 'undefined' ? (
        ''
        ) : (
            <div>
            <Header showHeader={false} cartFetch={false} />
            <header className="App-header">
                <Container className="h-100">
                  <Row bsPrefix="d-flex justify-content-center align-items-center h-100">
                    <Col md={6} lg={6} xl={7}>
                      <div className="duclo_logo">
                        <img
                          src={
                            process.env.REACT_APP_PROJECT ===
                            AppDefaults.PROJECT_MEGATRON
                              ? MegatronLogo
                              : brandLogo
                          }
                          className="mt-4 mb-4"
                          alt={process.env.REACT_APP_PROJECT ===
                            AppDefaults.PROJECT_MEGATRON ? constants.PROJECT_PAGE_TITLE_MEGATRON : constants.PROJECT_PAGE_TITLE_DUCLO}
                        />
                      </div>
                      <div className="card ">
                        <div className="card-body text-center">
                          <h5 style={{ marginTop: 40 }}
                          className={`${sessionexpire ? 'mb-2' : 'mb-4'}`}>
                            {t('SIGN_IN_PAGE_TITLE')}
                          </h5>
                          {sessionexpire && (
                            <div className="login-error-container mb-4">
                              <HiOutlineExclamationCircle
                                size={15}
                                stroke={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue('--error_24')}
                              />

                              <div className="error-text">
                                {constants.SESSION_EXPIRE_MESSAGE}
                              </div>
                            </div>
                          )}
                          {!keycloak.authenticated && (
                            <button
                              type="button"
                              className="btn btn-primary signin-btn w-25 mb-2"
                              onClick={() => handleLoginButtonClick()}
                            >
                              {constants.LOGIN_BUTTON_CONTENT}
                            </button>
                          )}

                          {!!keycloak.authenticated && (
                            <button
                              type="button"
                              className="btn-primary"
                              onClick={() =>{invalidateSessionApi()}}
                            >
                              {constants.TOP_HEADER_LOGOUT_SETTINGS_NAV_TITLE} (
                              {keycloak.tokenParsed.preferred_username})
                            </button>
                          )}

                          <p>
                            {location?.state !== null && show && (
                              <Alert
                                variant={location?.state?.status}
                                className="text-start"
                                onClose={() => setShow(false)}
                                dismissible
                              >
                                {location?.state?.msg}
                              </Alert>
                            )}
                          </p>
                          <p className="mb-5" style={{ fontSize: 14 }}>
                        {t('AGREES_DUCLO_CONTENT')}
                        <strong className="term-donction-text">
                          <a
                            href={privacyPolicy?privacyPolicy:''}
                            target="_blank"
                            className="primary_anchor"
                            rel="noreferrer"
                          >
                            {constants.FOOTER_NAV_PRIVACY_POLICY}
                          </a>
                        </strong>
                        and
                        <strong className="term-donction-text">
                          <a
                            href={termServices ? termServices :''}
                            target="_blank"
                            className="primary_anchor"
                            rel="noreferrer"
                          >
                            {constants.FOOTER_NAV_TERMS_CONDITION}
                          </a>
                        </strong>
                        {constants.AGREES_DUCLO_END_CONTENT}
                      </p>
                        </div>
                      </div>

                      <p style={{ fontSize: 14 }} className="mt-3">
                          {constants.DONT_HAVE_ACCOUNT_CONTENT}
                          <br />
                          {constants.NO_ORGANIZATION}{' '}
                          <strong>
                          <a
                          href={`${process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL}/becomeacustomer`}
                          class="primary_anchor"
                            >
                          {constants.CREATE_ORGANIZATION}
                          </a>

                          </strong>
                          <br />
                          {constants.ARE_YOU_EXISTING_STEP_PARTNER}{' '}
                          <strong>
                            <a
                              href={`${process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL}/createpartnerorg`}
                              class="primary_anchor"
                            >
                              {constants.JOIN_AS_A_STEP_PARTNER}
                            </a>
                          </strong>
                        </p>
                    </Col>
                  </Row>
                </Container>
                <SiteModal
                  key={'account-status-modal1'}
                  classes="account-status-modal"
                  wrapperClass="account-status-modal"
                  showModal={isActNotEnabled}
                  hideModal={() => setIsActNotEnabled(false)}
                  modalHeader={true}
                >
                  <AccountNotEnabledModal
                    onClickLogIn={() => {
                      setIsActNotEnabled(false);
                      loginUser();
                    }}
                  ></AccountNotEnabledModal>
                </SiteModal>
                <SiteModal
                  key={'account-status-modal2'}
                  classes="account-status-modal"
                  wrapperClass="account-status-modal"
                  showModal={isActNotActivated}
                  hideModal={() => setIsActNotActivated(false)}
                  modalHeader={true}
                >
                  <AccountNotVerifiedModal
                    onClickLogIn={() => {
                      setIsActNotActivated(false);
                     loginUser();
                    }}
                  ></AccountNotVerifiedModal>
                </SiteModal>
              </header>
          </div>
      )}
    </div>
  );
}

export default SignIn;
