const constants = {
  SIGN_IN_PAGE_TITLE: 'Sign in to Duclo',
  AGREES_DUCLO_CONTENT: "You agree to Duclo's",
  AGREES_DUCLO_END_CONTENT: 'by signing in.',
  DONT_HAVE_ACCOUNT_CONTENT:
    "Don't have an account? Ask your administrator to invite you into your organization.",
  CUSTOMERS_BREADCRUMB_TITLE: 'Customers',
  CUSTOMERS_INCIDENTS_TITLE: 'Incidents',
  PURCHASE_BREADCRUMB_TITLE: 'Purchase',
  PURCHASE_SUMMARY_BREADCRUMB_TITLE: 'Purchase Summary',
  PURCHASE_CONFIRMATION_BREADCRUMB_TITLE: 'Purchase Confirmation',
  LOGIN_BUTTON_CONTENT: 'Login',
  CLOSE_BUTTON: 'Close',
  SEND_ME_AN_INVOICE_BUTTON_LABEL: 'Send Me An Invoice',
  PAY_WITH_CREDIT_CARD_BUTTON_LABEL: 'Pay With Credit Card',
  PURCHASE_SUMMARY_PRODUCT_NAME_LABEL: 'Product Name',
  PURCHASE_SUMMARY_AMOUNT_LABEL: 'Amount',
  PURCHASE_SUMMARY_QUANTITY_LABEL: 'Qty',
  PURCHASE_SUMMARY_SKU_LABEL: 'SKU',
  PURCHASE_CONFIRMATION_ADD_LICENSE_BUTTON_LABEL: 'Assign Licenses',
  PURCHASE_CONFIRMATION_VIEW_RECEIPT_BUTTON_LABEL: 'View Receipt',
  PURCHASE_CONFIRMATION_BACK_TO_ORG_SETTINGS_BUTTON_LABEL:
    'Back to Organization Settings',
  PURCHASE_SERVICES_BUTTON_LABEL: 'Purchase Services',
  TOP_HEADER_CUSTOMERS_NAV_TITLE: 'Customers',
  TOP_HEADER_DASHBOARD_NAV_TITLE: 'Dashboard',
  TOP_HEADER_CAMERAS_NAV_TITLE: 'Camera Wall',
  TOP_HEADER_EVENT_NAV_TITLE: 'Events',
  TOP_HEADER_INCIDENTS_NAV_TITLE: 'Incidents',
  TOP_HEADER_LIVE_NAV_TITLE: 'Live',
  TOP_HEADER_SEARCH_NAV_TITLE: 'Search',
  TOP_HEADER_USERS_NAV_TITLE: 'Users',
  TOP_HEADER_ACCOUNT_SETTINGS_NAV_TITLE: 'Account Settings',
  TOP_HEADER_ORG_SETTINGS_NAV_TITLE: 'Organization Settings',
  TOP_HEADER_PERSONAL_SETTINGS_NAV_TITLE : 'Personal settings',
  TOP_HEADER_LOGOUT_SETTINGS_NAV_TITLE: 'Logout',
  ANALYTICS_DASHBOARD_CATEGORY_DEFAULT_LABEL: 'All Categories',
  ANALYTICS_DASHBOARD_RETAIL_PERFORMANCE_SECTION_TITLE: 'Retail Performance',
  ANALYTICS_DASHBOARD_RETAIL_PERFORMANCE_SECTION_DESCRIPTION: 'Last Update:',
  ANALYTICS_DASHBOARD_RETAIL_PERFORMANCE_NO_QUEUES_NEARING_CAPACITY:
    'No queues nearing capacity',
  ANALYTICS_DASHBOARD_RETAIL_PERFORMANCE_NO_QUEUES_AT_OR_OVER_CAPACITY:
    'No queues at or over capacity',
  ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SECTION_TITLE: 'Camera Availability',
  ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SECTION_DESCRIPTION:
    'The performance of camera online within a one-hour period of time',
  ANALYTICS_DASHBOARD_CAMERA_MINUTES_UNAVAILABLE_SECTION_TITLE:
    'Camera Minutes Unavailable',
  ANALYTICS_DASHBOARD_CAMERA_MINUTES_UNAVAILABLE_SECTION_DESCRIPTION:
    'The sum of the total minutes a camera is offline within a one-hour period of time',
  ANALYTICS_DASHBOARD_CAMERA_AVERAGE_STREAMING_BITRATE_SECTION_TITLE:
    'Camera Average Streaming Bitrate',
  ANALYTICS_DASHBOARD_CAMERA_AVERAGE_STREAMING_BITRATE_SECTION_DESCRIPTION:
    'The average bitrate recorded within a one-hour increment by camera',
  ANALYTICS_DASHBOARD_CAMERA_AVERAGE_STREAMING_BITRATE_HIGHLIGHT_DESCRIPTION:
    'Average bitrate',
  ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_MODAL_TITLE: 'Camera Availability',
  ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_HIGHLIGHT_DESCRIPTION:
    'Avg. time camera online',
  ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SERIES_LABEL: 'Time Camera Online',
  ANALYTICS_DASHBOARD_VIEW_ALL_LINK: 'View All',
  ANALYTICS_DASHBOARD_VIEW_DETAILS_LINK: 'View Details',
  ANALYTICS_DASHBOARD_ALL_CAMERAS_LINK: 'All Cameras',
  ANALYTICS_DASHBOARD_ALL_CATEGORIES_LABEL: 'All Categories',
  ANALYTICS_DASHBOARD_NO_DATA_AVAILABLE_TEXT: 'No Data Available',
  ANALYTICS_DASHBOARD_DURATION_ITEM_HOUR: 'Hour',
  ANALYTICS_DASHBOARD_DURATION_ITEM_DAY: 'Day',
  ANALYTICS_DASHBOARD_DURATION_ITEM_WEEK: 'Week',
  ANALYTICS_DASHBOARD_DURATION_ITEM_MONTH: 'Month',
  ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_24_HOURS: 'Past 24 Hours',
  ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_48_HOURS: 'Past 48 Hours',
  ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS: 'Past 7 Days',
  ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_14_DAYS: 'Past 14 Days',
  ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_30_DAYS: 'Past 30 Days',
  ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_YEAR: 'Past Year',
  ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_2_YEARS: 'Past 2 Years',
  ANALYTICS_DASHBOARD_RANGE_CUSTOM_RANGE: 'Custom Range',
  ACCOUNT_SETTINGS_PAGE_TITLE: 'Account Settings',
  ACCOUNT_SETTINGS_PROFILE_BUTTON_LABEL: 'Save Changes',
  ACCOUNT_SETTINGS_PROFILE_IMAGE_SUCCESS_TEXT:
    'Profile Image updated successfully.',
  ACCOUNT_SETTINGS_PROFILE_SETTINGS_SUCCESS_TEXT:
    'Account settings updated successfully.',
  ORGANIZATION_SETTINGS_PAGE_TITLE: 'Organization Settings',
  ORGANIZATION_SETTINGS_PROFILE_SETTINGS_SUCCESS_TEXT:
    'Organization updated successfully.',
  ACCOUNT_SETTINGS_PROFILE_IMAGE_ERROR_TEXT:
    'Invalid file type. Please select a JPG or PNG image.',
  CREATE_ORGANIZATION: 'Become a Customer',
  NO_ORGANIZATION: "Don't have an organization?",
  SIDEBAR_MENU_HOME_LABEL: 'Home',
  SIDEBAR_MENU_SERVICES_LABEL: 'Services',
  SIDEBAR_MENU_USERS_LABEL: 'Users',
  SIDEBAR_LOCATION_AND_AREAS_LABEL: 'Location & Areas',
  SIDEBAR_HOME_SUBMENU_MANAGE_ORG_LABEL: 'Manage Organization',
  SIDEBAR_HOME_SUBMENU_NEW_ORG_LABEL: 'New Organization',
  SIDEBAR_USERS_SUBMENU_MANAGE_LABEL: 'Manage',
  SIDEBAR_USERS_SUBMENU_NEW_USER_LABEL: 'New User',
  SIDEBAR_LOCATION_AND_AREAS_SUBMENU_MANAGE_LABEL: 'Manage',
  SIDEBAR_LOCATION_AND_AREAS_SUBMENU_NEW_LABEL: 'New',
  CAMERAS_LIVE_ACTION_BUTTONS_CANCEL_LABEL: 'Cancel',
  CAMERAS_LIVE_ACTION_BUTTONS_CONFIRM_LABEL: 'Confirm',
  CAMERAS_LIVE_PLAYBACK_ADD_TO_INCIDENT_TEXT: 'Add to Incident',
  CAMERAS_LIVE_PLAYBACK_GO_LIVE_BUTTON_LABEL: 'Go Live',
  CAMERAS_LIVE_PLAYBACK_CLIPPER_DURATION_UNIT: 'Secs',
  CAMERAS_LIVE_PLAYBACK_CLIPPER_SAVE_BUTTON_LABEL: 'Save Clip',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_CREATE_CLIP_BUTTON_LABEL: 'Create Clip',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_STARTS_AT_TEXT: 'Starts at',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_ENDS_AT_TEXT: 'Ends at',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_COMMENT_PLACEHOLDER: 'Add a comment',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_NUM_OF_CHARACTERS_LIMIT:
    '$numOfCharacters / $characterLimit max. characters',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_TAGS_TEXT: 'Tags',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_SHORTCUTS_TEXT: 'Shortcuts',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_VIDEO_PROCESSING_TITLE:
    'Video Clip is being processed.',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_VIDEO_PROCESSING_MESSAGE:
    'You will receive an email with your video clip as soon as it is done processing.',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_CLIP_CREATED_NOTICE:
    'Clip Created Successfully',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_STORAGE_CAPACITY_NOTICE_TITLE:
    'Storage Capacity Exceeded',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_STORAGE_CAPACITY_NOTICE_DESCRIPTION:
    'Your organization had exceeded the maximum storage space.',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_GO_TO_CLIPS_BUTTON_LABEL: 'Go to Clips',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_SHARE_BUTTON_LABEL: 'Share',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_WATCH_CLIP_BUTTON_LABEL: 'Watch Clip',
  CAMERAS_LIVE_CREATE_CLIP_MODAL_CLOSE_BUTTON_LABEL: 'Close',
  CAMERAS_LIVE_ADD_TO_INCIDENT_MODAL_CONFIRM_BUTTON_LABEL: 'Confirm',
  CAMERAS_LIVE_ADD_TO_INCIDENT_MODAL_CREATE_NEW_INCIDENT_BUTTON_LABEL:
    '+ Create New Incident',
  CAMERAS_LIVE_ADD_TO_INCIDENT_MODAL_ERROR:
    'An error occurred while processing request.',
  CAMERAS_LIVE_ADD_TO_INCIDENT_CONFIRMATION_MODAL_TITLE:
    'Added Clip to Incident',
  CAMERAS_LIVE_ADD_TO_INCIDENT_CONFIRMATION_MODAL_DESCRIPTION:
    'Your clip is being added to the incident and will be made available once it is generated.',
  CAMERAS_LIVE_ADD_TO_INCIDENT_CONFIRMATION_MODAL_BACK_TO_VIDEO_BUTTON_LABEL:
    'Back to Video',
  CAMERAS_LIVE_ADD_TO_INCIDENT_CONFIRMATION_MODAL_GO_TO_INCIDENT_BUTTON_LABEL:
    'Go to Incident',
  CAMERAS_VIDEO_WALL_TITLE: 'Cameras',
  CAMERAS_VIDEO_WALL_CAMERA_OFFLINE_TITLE: 'Camera is offline',
  CAMERAS_VIDEO_WALL_DEFAULT_VIEW_TITLE: 'Default View',
  CAMERAS_VIDEO_WALL_MY_VIEWS_TITLE: 'My Views',
  CAMERAS_VIDEO_WALL_DEFAULT_LOCATION_NAME_TITLE: 'Location Name',
  CAMERAS_VIDEO_WALL_SAVE_VIEW_MODAL_TITLE: 'Save View As',
  CAMERAS_VIDEO_WALL_UPDATE_VIEW_MODAL_TITLE:
    'Do you want to overwrite your existing view?',
  CAMERAS_VIDEO_WALL_FILTER_MODAL_TITLE: 'Filter',
  CAMERAS_VIDEO_WALL_FILTER_MODAL_DESCRIPTION:
    'Filter by location, areas and cameras',
  CAMERAS_VIDEO_WALL_FILTER_MODAL_APPLY_FILTER_LABEL: 'Apply Filters',
  CAMERAS_VIDEO_WALL_FILTER_MODAL_CLEAR_FILTER_LINK: 'Clear Filter',
  CAMERAS_VIDEO_WALL_SAVE_VIEW_MODAL_DEFAULT_VIEW_NAME_TITLE: 'My New View',
  CAMERAS_VIDEO_WALL_SAVE_VIEW_MODAL_DESCRIPTION:
    'Creating a view will preserve your filters and layout',
  CAMERAS_VIDEO_WALL_UPDATE_VIEW_MODAL_DESCRIPTION:
    'We’ve noticed you’ve made some changes to the layout.  Would you like to save this view?',
  CAMERAS_VIDEO_WALL_FILTER_BUTTON_LABEL: 'Filter',
  CAMERAS_VIDEO_WALL_SAVE_VIEW_BUTTON_LABEL: 'Save View',
  CAMERAS_VIDEO_WALL_UPDATE_VIEW_MODAL_UPDATE_BUTTON_LABEL: 'Yes, Update View',
  CAMERAS_VIDEO_WALL_UPDATE_VIEW_MODAL_SAVE_NEW_BUTTON_LABEL:
    'No, Save As New View',
  CAMERAS_VIDEO_WALL_SAVE_AS_NEW_VIEW_BUTTON_LABEL: 'Save As New View',
  CAMERAS_VIDEO_WALL_RESET_VIEW_BUTTON_LABEL: 'Reset View',
  CAMERAS_VIDEO_WALL_UPDATE_VIEW_BUTTON_LABEL: 'Update View',
  CAMERAS_VIDEO_WALL_ADD_DEVICE_BUTTON_LABEL: 'Add Device',
  CAMERAS_VIDEO_WALL_NO_CAMERAS_AVAILABLE_LABEL: 'No cameras available.',
  CAMERAS_VIDEO_WALL_SPECIFY_VIEW_NAME_ERROR:
    'Please specify a name for the view',
  LOCATION_DROPDOWN_ALL_LOCATIONS_LABEL: 'All Locations',
  LOCATION_DROPDOWN_ALL_AREAS_LABEL: 'All Areas',
  LOCATION_DROPDOWN_ALL_AREAS_ID: 'Area0',
  LOCATION_DROPDOWN_NO_AREA_DATA_TEXT: 'No area data',
  LOCATION_DROPDOWN_NO_LOCATION_DATA_TEXT: 'No location data',
  MANAGE_ORG_PAGE_TITLE: 'Customers',
  MANAGE_ORG_CUSTOMER_LANDING_PAGE_TITLE: 'Hey $firstName, welcome back!',
  MANAGE_ORG_ADD_ORG_BUTTON_TITLE: 'Add Organization',
  MANAGE_ORG_ADD_USER_BUTTON_TITLE: 'Add User',
  MANAGE_ORG_OFFLINE_CAMERA_TITLE: 'Offline Cameras',
  MANAGE_ORG_LICENSES_EXPIRE_TITLE: 'Expiring Soon',
  MANAGE_ORG_NO_CONTENT_FOUND: 'No Customers Found',
  MANAGE_ORG_CUSTOMER_TABLE_NAV_TITLE: 'Edit User',
  MANAGE_ORG_INACTIVE_USER_ALERT_TITLE: 'Your account is deactivated.',
  MANAGE_ORG_INACTIVE_USER_ALERT_CONTENT:
    'Your account is deactivated. Please contact your organization admin for support.',
  NO_MANAGE_ORG_CUSTOMER_FOUND_TITLE: 'You currently have no customers.',
  NEW_CUSTOMER_ORG_PAGE_TITLE: 'Add New Organization',
  NEW_CUSTOMER_TITLE: 'Add New User',
  NEW_PERSONAL_SETTING_TITLE: 'Personal settings',
  NEW_CUSTOMER_ORG_SUBMIT_BUTTON_TITLE: 'Create Customer',
  NEW_USER_SUBMIT_BUTTON_TITLE: 'Create User',
  BACK_BUTTON_TITLE: 'back',
  EDIT_CUSTOMER_TITLE: 'Edit User',
  EDIT_CUSTOMER_INVITATION_TITLE: 'Invitation Sent!',
  EDIT_CUSTOMER_BACK_TO_USERS_LIST_BUTTON_TITLE: 'Back To Users',
  EDIT_CUSTOMER_ACTIVE_STATUS_TITLE: 'Active',
  EDIT_CUSTOMER_INACTIVE_STATUS_TITLE: 'User not activated',
  EDIT_CUSTOMER_RESEND_INVITATION_BUTTON_TITLE: 'Resend Invitation',
  EDIT_CUSTOMER_RESEND_INVITATION_POPUP_CONTENT:
    'We sent a new invitation to $email',
  EDIT_CUSTOMER_RESET_PASSWORD_BUTTON_TITLE: 'Reset Password',
  EDIT_CUSTOMER_RESET_PASSWORD_POPUP_CONTENT:
    'We sent a reset password email to $email',
  EDIT_CUSTOMER_RESET_PASSWORD_EDIT_PROFILE_BUTTON: 'Edit $firstName’s Profile',
  EDIT_CUSTOMER_REMOVE_USER_BUTTON_TITLE: 'Remove User',
  EDIT_CUSTOMER_REMOVE_TECH_BUTTON_TITLE: 'Remove Tech',
  EDIT_CUSTOMER_REMOVE_TECH_PARTNER_ADMIN_ERROR_MEGSSAGE: 'Partner admin does not have permission to delete another partner admin.',
  EDIT_CUSTOMER_REMOVE_USER_CONFIRM_BUTTON_TITLE: 'Remove This Account',
  EDIT_CUSTOMER_REMOVE_USER_POPUP_CONTENT:
    "Are you sure you want to remove $firstName $lastName's account?",
  EDIT_CUSTOMER_DEACTIVATE_USER_CONFIRM_BUTTON_TITLE: 'Deactivate This Account',
  EDIT_CUSTOMER_ACTIVATE_USER_CONFIRM_BUTTON_TITLE: 'Activate This Account',
  EDIT_CUSTOMER_DEACTIVATE_USER_ACCESS_CONTENT:
    'Access will be removed for this account.',
  EDIT_CUSTOMER_ACTIVE_USER_ACCESS_CONTENT:
    'Access will be given for this account.',
  EDIT_CUSTOMER_ACTIVE_USER_ACCESS_TITLE:
    'Are you sure you want to activate account?',
  EDIT_CUSTOMER_DEACTIVATE_POPUP_CONTENT:
    "Are you sure you want to deactivate $firstName $lastName's account?",
  EDIT_CUSTOMER_REMOVE_USER_CONFIRM_CANCEL_BUTTON_TITLE: 'Cancel',
  EDIT_CUSTOMER_REMOVE_USER_MODAL_REMOVE_CONTENT:
    'The account will be completely removed from the system.',
  EDIT_USER_SUBMIT_BUTTON_TITLE: 'Save Changes',
  EDIT_CUSTOMER_ORG_PAGE_TITLE: 'Manage Organization',
  EDIT_CUSTOMER_ORG: 'Save',
  EDIT_CUSTOMER_ORG_CHANGES: 'Save Changes',
  EDIT_ORG_PAGE_TITLE: 'Edit Customer',
  ORG_DASHBOARD_PAGE_TITLE: 'Organization Detail',
  ORG_DASHBOARD_LOCATIONS_SECTION_TITLE: 'Locations',
  ORG_DASHBOARD_LOCATIONS_SECTION_NAVIGATE_TEXT: 'See All',
  ORG_DASHBOARD_LOCATIONS_SECTION_HEADING: 'Main Address',
  ORG_DASHBOARD_LICENSES_ADD_LICENSE_SELECT_LICENSE_TITLE: 'Select License',
  ORG_DASHBOARD_LICENSES_ADD_LICENSE_LICENSE_ACTIVATION_TITLE:
    'License Activation Date',
  ORG_DASHBOARD_LICENSES_ADD_LICENSE_CONFIRM_LABEL: 'Confirm',
  ORG_DASHBOARD_LICENSES_ADD_LICENSE_NUMBER_OF_LICENSES_AVAILABLE:
    '$numOfLicenses licenses available',
  ORG_DASHBOARD_LICENSES_ADD_LICENSE_EXPIRES: 'Expires: $expirationDate',
  ORG_DASHBOARD_LICENSES_ADD_LICENSE_KEY: 'License Key: $licenseKey',
  ORG_DASHBOARD_LICENSES_DETACH_LICENSE_DESCRIPTION:
    'If you deactivate this license, all features and storage associated with this device will be deleted from your account.',
  ORG_DASHBOARD_LICENSES_DETACH_LICENSE_LABEL: 'Detach License',
  ORG_DASHBOARD_LICENSES_CANCEL_LABEL: 'Cancel',
  ORG_DASHBOARD_LICENSES_EXPIRING_SECTION_TITLE: 'Licenses Expiring Soon',
  ORG_DASHBOARD_EDIT_BUTTON_TITLE: 'Edit Customer',
  ORG_DASHBOARD_LICENSES_SECTION_TITLE: 'Licenses',
  ORG_DASHBOARD_LICENSES_EXPIRED_TITLE: 'Expired',
  ORG_DASHBOARD_LICENSES_EXPIRING_SOON_TITLE: 'Licenses Expiring Soon',
  ORG_DASHBOARD_LICENSES_AVAILABLE_TITLE: 'Licenses Available',
  ORG_DASHBOARD_LICENSES_USED_TITLE: 'Licenses Used',
  ORG_DASHBOARD_LICENSES_SECTION_NAVIGATE_TEXT: 'See All',
  ORG_DASHBOARD_USED_LICENSES_SECTION_TITLE: 'Used',
  ORG_DASHBOARD_AVAILABLE_LICENSES_SECTION_TITLE: 'Available',
  ORG_DASHBOARD_DEVICES_SECTION_TITLE: 'Devices',
  ORG_DASHBOARD_DEVICES_SECTION_NAVIGATE_TEXT: 'View All',
  ORG_DASHBOARD_DEVICES_OFFLINE_CAMERAS_SECTION_TITLE: 'Offline Cameras',
  ORG_DASHBOARD_UNCLAIMED_DEVICES_SECTION_TITLE: 'Unclaimed Devices',
  ORG_DASHBOARD_REQUIRED_ACTIVATION_DEVICES_SECTION_TITLE:
    'Requiring Activation',
  ORG_DASHBOARD_ONLINE_DEVICES_SECTION_TITLE: 'Online Cameras',
  ORG_DASHBOARD_TECHNICIANS_SECTION_TITLE: 'Assigned Technicians',
  ORD_DASHBOARD_MESSAGE:
    'In order to access this customer, you must be assigned to the organization as a technician.',
  ORG_DASHBOARD_ADMIN: 'Organization Admin',
  ORG_DASHBOARD_TECHNICIANS_NAVIGATE_TEXT: 'Assign',
  ORG_DASHBOARD_PARTNER_SECTION_TITLE: 'Partner',
  ORG_DASHBOARD_STEP_PARTNER_SECTION_TITLE: 'STEP Partner',
  ORG_DASHBOARD_PARTNER_REMOVE_NAVIGATE_TEXT: 'Remove',
  ORG_DASHBOARD_PARTNER_INVITE_NAVIGATE_TEXT: 'Assign',
  ORG_DASHBOARD_PARTNER_ORG_NAME_LBAEL: 'Partner Organization Name',
  ORG_DASHBOARD_PARTNER_ADDRESS_LBAEL: 'Address',
  ORG_DASHBOARD_PARTNER_PHONE_NUMBER_LBAEL: 'Phone Number',
  ORG_DASHBOARD_PARTNER_EMAIL_LBAEL: 'Email',
  ORG_DASHBOARD_USER_SECTION_TITLE: 'Users',
  ORG_DASHBOARD_USER_SECTION_NAVIGATE_TEXT: 'Add Users',
  ASSIGN_TECHNICIANS_SUCCESS_TEXT: 'Technicians updated successfully.',
  ASSIGN_TECHNICIANS_LOADER_TEXT: 'Saving',
  ASSIGN_TECHNICIANS_SAVED_TEXT: 'Saved',
  ASSIGN_TECHNICIANS_ERROR_MODAL_TITLE: 'Something Went Wrong!',
  ASSIGN_TECHNICIANS_ERROR_MODAL_CONTENT:
    'Please refresh your browser and try again.',
  ASSIGN_TECHNICIANS_ERROR_MODAL_BUTTON_TITLE: 'Refresh Your Browser',
  NO_ASSIGN_TECHNICIANS_FOUND_TITLE: 'No technicians assigned.',
  USERS_PAGE_TITLE: 'Users',
  NEW_USER_PAGE_TITLE: 'Add a new user',
  EDIT_USER_PAGE_TITLE: 'Manage user',
  PARTNER_USERS_PAGE_TITLE: 'Techs',
  PARTNER_MANAGE_EDIT_USER_NAV_TITLE: 'Edit tech',
  PARTNER_MANAGE_ASSIGN_CUSTOMER_NAV_TITLE: 'Assign customer',
  ADD_PARTNER_USER_BUTTON_TITLE: 'Add Techs',
  PARTNER_NEW_USER_PAGE_TITLE: 'Add a New Tech',
  PARTNER_NEW_USER_SUBMIT_BUTTON_TITLE: 'Create Tech Account',
  PARTNER_NEW_USER_ASSIGN_CUSTOMERS_POPUP_TITLE: 'Assign Customer',
  PARTNER_ASSIGN_CUSTOMER_BUTTON_TITLE: 'Save',
  PARTNER_EDIT_USER_PAGE_TITLE: 'Manage user',
  PARTNER_EDIT_USER_SUBMIT_BUTTON_TITLE: 'Save Changes',
  PARTNER_EDIT_ACTIVE_STATUS_TITLE: 'Active',
  PARTNER_EDIT_INACTIVE_STATUS_TITLE: 'Pending Invitation',
  PARTNER_EDIT_SEND_INVITATION_BUTTON_TITLE: 'Resend Invitation',
  PARTNER_EDIT_DEACTIVATE_BUTTON_TITLE: 'Deactivate Account',
  PARTNER_EDIT_ASSIGN_CUSTOMER_CONTENT:
    'Check the box below to give this user access to one or more customer organizations.',
  PARTNER_EDIT__RESET_PASSWORD_POPUP_CONTENT: 'Reset Password Email Sent!',
  PARTNER_EDIT_ASIGN_CUSTOMER_POPUP_TITLE: 'Assign Customer',
  NO_PARTNER_USERS_FOUND_TITLE: 'No Users Found',
  NO_USERS_FOUND_TITLE: 'No User Found',
  TECHNICIANS_PAGE_TITLE: 'Technicians',
  NEW_TECHNICIANS_PAGE_TITLE: 'Add a new technician',
  EDIT_TECHNICIANS_PAGE_TITLE: 'Manage technician',
  LOCATIONS_PAGE_TITLE: 'Locations',
  LOCATIONS_REMOVE_AREA_BUTTON_TITLE: 'Remove Area',
  NEW_LOCATIONS_PAGE_TITLE: 'Add New Location',
  NEW_LOCATIONS_SUBMIT_BUTTON_TITLE: 'Create Location',
  NEW_LOCATIONS_REMOVE_BUTTON_TITLE: 'Remove Location',
  NEW_LOCATIONS_BUTTON_TITLE: 'Add Location',
  NEW_LOCATIONS_AREA_LABEL_TEXT: 'Areas',
  NEW_LOCATIONS_AREA_LABEL_INFO:
    'Areas can be used as ways to categorize your layout.',
  NEW_LOCATIONS_MARKER_EDIT_BUTTON_TITLE: 'Edit',
  NEW_LOCATIONS_MARKER_BUTTON_TITLE: 'View Devices',
  NEW_LOCATIONS_MARKER_LOCATION_NAME_LABEL: 'Location Name',
  NEW_LOCATIONS_MARKER_LOCATION_ADDRESS_LABEL: 'Location Address',
  EDIT_LOCATIONS_PAGE_TITLE: 'Edit Location',
  EDIT_LOCATIONS_SUBMIT_BUTTON_TITLE: 'Save Changes',
  EDIT_LOCATIONS_REMOVE_BUTTON_TITLE: 'Remove This Location',
  EDIT_LOCATIONS_REMOVE_MODAL_TITLE:
    'Unable to remove the Location $locationName.',
  EDIT_LOCATIONS_MODAL_REMOVE_CONTENT: '',
  EDIT_LOCATIONS_MODAL_DEVICE_EXISTS_REMOVE_CONTENT:
    'There are devices associated with the areas under this location. Please re-assign the devices before removing this location.',
  EDIT_LOCATIONS_REMOVE_AREA_MODAL_TITLE:
    'Are You Sure You Want to Remove $areaName Area?',
  EDIT_LOCATIONS_REMOVE_AREA_MODAL_DEVICE_EXISTS_TITLE:
    'Unable to Remove The Area $areaName.',
  EDIT_LOCATIONS_REMOVE_AREA_MODAL_REMOVE_CONTENT:
    'Removing this area will move all associated device to the location’s default area. Are you sure you want to remove “$areaName Area”?',
  EDIT_LOCATIONS_REMOVE_AREA_MODAL_DEVICE_EXISTS_CONTENT:
    'Removing this area will move all associated device to the location’s default area. Are you sure you want to remove “Secondary Area”?',
  EDIT_LOCATIONS_CONFIRM_DELETE_CONTENT: 'Are you sure to delete?',
  INCIDENTS_PAGE_TITLE: 'Incidents',
  INCIDENTS_PAGE_NO_CONTENT_MESSAGE: 'You currently have no incidents',
  INCIDENTS_PAGE_CLOSE_INCIDENT_BUTTON_LABEL: 'Close Incident',
  INCIDENTS_PAGE_REOPEN_BUTTON_LABEL: 'Reopen',
  INCIDENTS_PAGE_SAVE_BUTTON_LABEL: 'Save Changes',
  INCIDENTS_PAGE_TAB_DETAILS_LABEL: 'Details',
  INCIDENTS_PAGE_TAB_EVIDENCE_LABEL: 'Evidence',
  INCIDENTS_PAGE_TAB_CONTRIBUTORS_LABEL: 'Contributors',
  INCIDENTS_PAGE_TAB_EXPORT_LABEL: 'Export',
  INCIDENTS_PAGE_CONTEXT_MENU_DETAILS_LABEL: 'Details',
  INCIDENTS_PAGE_CONTEXT_MENU_EVIDENCE_LABEL: 'Evidence',
  INCIDENTS_PAGE_CONTEXT_MENU_CONTRIBUTORS_LABEL: 'Contributors',
  INCIDENTS_PAGE_CONTEXT_MENU_EXPORT_LABEL: 'Export',
  INCIDENTS_DETAILS_STATUS_TITLE: 'Status',
  INCIDENTS_DETAILS_INCIDENT_LOCATION_TIMEZONE_TITLE:
    'Incident Location Timezone',
  INCIDENTS_DETAILS_INCIDENT_DATE_TITLE: 'Incident Date',
  INCIDENTS_DETAILS_INCIDENT_TIME_TITLE: 'Incident Time',
  INCIDENTS_DETAILS_CREATED_BY_TEXT: 'Created by $fullName',
  INCIDENTS_DETAILS_CREATED_ON_TEXT: 'on $dateTimeZone',
  INCIDENTS_DETAILS_TIME_ZONE_TITLE: 'Time Zone',
  INCIDENTS_DETAILS_LOCATION_TITLE: 'Location',
  INCIDENTS_DETAILS_NO_INCIDENTS_TEXT: 'You currently have no incidents',
  INCIDENT_DETAILS_CLOSE_INCIDENT_NOTES_NOTE_INFO:
    'Up to $maxCharacters characters',
  INCIDENTS_EVIDENCE_INCIDENT_COMMENT_PLACEHOLDER:
    'Add comments and/or upload a file (.pdf, .doc, .jpeg, .png, .raw, .mp4, .zip.  Max file size: $maxFileSizInMBMB',
  INCIDENTS_EVIDENCE_INCIDENT_UPLOAD_ATTACH_FILE_BUTTON_LABEL: 'Attach File',
  INCIDENTS_EVIDENCE_INCIDENT_UPLOAD_SUBMIT_BUTTON_LABEL: 'Submit',
  INCIDENTS_EVIDENCE_INCIDENT_UPLOAD_UPLOAD_BUTTON_LABEL: 'Upload',
  INCIDENTS_EVIDENCE_INCIDENT_EVIDENCE_AND_NOTES_TITLE: 'Evidence & Notes',
  INCIDENTS_EVIDENCE_INCIDENT_EXPORT_SERVICE_NOTICE:
    'You can export the incident from our web app.',
  INCIDENTS_EVIDENCE_INCIDENT_NO_CONTENT_TITLE: 'No file or comments.',
  INCIDENTS_EVIDENCE_INCIDENT_NO_CONTENT_SUBTITLE:
    'You can add a comment or upload a file from above. To add a clip from your cameras, goto Devices to trim a footage.',
  INCIDENTS_EVIDENCE_INCIDENT_ATTACHMENT_TEXT: 'Attachment:',
  INCIDENTS_EVIDENCE_EVIDENCE_TYPE_COMMENT: 'Comment',
  INCIDENTS_EVIDENCE_EVIDENCE_TYPE_DOCUMENT: 'Document',
  INCIDENTS_EVIDENCE_EVIDENCE_TYPE_VIDEO: 'VIdeo',
  INCIDENTS_EVIDENCE_EVIDENCE_TYPE_IMAGE: 'Image',
  INCIDENTS_EVIDENCE_NOTE_DETAILS_VIEW_ALL: 'View All',
  INCIDENTS_EVIDENCE_NOTE_DETAILS_VIEW_FILE: 'View File',
  INCIDENTS_EVIDENCE_NOTE_DETAILS_VIEW_LOG: 'View Log',
  INCIDENTS_EVIDENCE_NOTE_DETAILS_CREATED_BY: 'Created by',
  INCIDENTS_EVIDENCE_NOTE_DETAILS_NOTES: 'Notes',
  INCIDENTS_EVIDENCE_NOTE_DETAILS_COMMENTS_BY: 'Comments by',
  INCIDENTS_EVIDENCE_NOTE_DETAILS_CAPTURED_DATE_AND_TIME:
    'Captured Date & Time',
  INCIDENTS_EXPORT_EXPORT_INCIDENT_TITLE_TEXT: 'Export Incident',
  INCIDENT_EXPORT_EXPORT_LOG_NOTHING_LOGGED: 'Nothing logged',
  INCIDENTS_EXPORT_EXPORT_LOG_TITLE_TEXT: 'Export Log',
  INCIDENTS_EXPORT_DESCRIPTION_TEXT:
    'All files and notes will be compressed into a zipped file.',
  INCIDENTS_EXPORT_EXPORT_FILE_BUTTON_LABEL: 'Export File',
  INCIDENTS_EXPORT_TRANSCODE_VIDEO_OPTION_TEXT:
    'Transcode H.265 video clips to H.264',
  INCIDENTS_EXPORT_FILE_COMPRESSION_IN_PROGRESS_TEXT:
    'File compression in progress',
  INCIDENTS_EXPORT_FILE_COMPRESSION_EMAIL_NOTIFICATION_TEXT:
    'We will send you a notification when the incident is ready to download. Once the file is ready, you will have 48 hours to access and save.',
  INCIDENTS_PAGE_GRID_COLUMN_HEADER_DISPLAY_NAME: 'Summary',
  INCIDENTS_PAGE_GRID_COLUMN_HEADER_DISPLAY_INCIDENT_ID: 'Incident ID',
  INCIDENTS_PAGE_GRID_COLUMN_HEADER_DISPLAY_TAGS: 'Tags',
  INCIDENTS_PAGE_GRID_COLUMN_HEADER_DISPLAY_INCIDENT_DATE: 'Incident Date',
  INCIDENTS_PAGE_GRID_COLUMN_HEADER_DISPLAY_STATUS: 'Status',
  INCIDENTS_PAGE_GRID_INCIDENT_STATUS_OPEN: 'Open',
  INCIDENTS_PAGE_GRID_INCIDENT_STATUS_REOPENED: 'Re-opened',
  INCIDENTS_PAGE_GRID_INCIDENT_STATUS_INCOMPLETE: 'Incomplete',
  INCIDENTS_PAGE_GRID_INCIDENT_STATUS_CLOSED: 'Closed',
  INCIDENTS_PAGE_GRID_INCIDENT_STATUS_CLOSED_RESOLVED: 'Closed - Resolved',
  INCIDENTS_PAGE_GRID_INCIDENT_STATUS_CLOSED_UNRESOLVED: 'Closed - Unresolved',
  INCIDENTS_DETAILS_EVIDENCE_GRID_COLUMN_HEADER_FILE_TYPE: 'File Type',
  INCIDENTS_DETAILS_EVIDENCE_GRID_COLUMN_HEADER_FILE_NAME: 'File Name',
  INCIDENTS_DETAILS_EVIDENCE_GRID_COLUMN_HEADER_ADDED_BY: 'Added By',
  INCIDENTS_DETAILS_EVIDENCE_GRID_COLUMN_HEADER_DATE_AND_TIME_ADDED:
    'Date And Time Added',
  INCIDENTS_DETAILS_EVIDENCE_GRID_NOTE_TYPE_VIDEO: 'Video',
  INCIDENTS_DETAILS_EVIDENCE_GRID_NOTE_TYPE_DOCUMENT: 'Document',
  INCIDENTS_DETAILS_EVIDENCE_GRID_NOTE_TYPE_COMMENT: 'Comment',
  INCIDENTS_MODAL_STATUS_FILTER_OPEN_LABEL: 'Open',
  INCIDENTS_MODAL_STATUS_FILTER_CLOSED_LABEL: 'Closed',
  INCIDENTS_MODAL_STATUS_FILTER_APPLY_BUTTON_LABEL: 'Apply',
  INCIDENTS_MODAL_NEW_INCIDENT_TITLE: 'New Incident',
  INCIDENTS_MODAL_NEW_INCIDENT_SUMMARY_PLACEHOLDER: 'Summary',
  INCIDENTS_MODAL_NEW_INCIDENT_DETAILS_PLACEHOLDER: 'Details',
  INCIDENTS_MODAL_NEW_INCIDENT_DETAILS_LOCATION_TIMEZONE:
    'Incident Location Timezone',
  INCIDENTS_MODAL_NEW_INCIDENT_DETAILS_INCIDENT_DATE: 'Incident Date',
  INCIDENTS_MODAL_NEW_INCIDENT_DETAILS_INCIDENT_TIME: 'Incident Time',
  INCIDENTS_MODAL_NEW_INCIDENT_DETAILS_INCIDENT_LOCATION: 'Location',
  INCIDENTS_MODAL_NEW_INCIDENT_SPECIFY_SUMMARY_ERROR:
    'Please provide a summary',
  INCIDENTS_MODAL_NEW_INCIDENT_SPECIFY_DETAILS_ERROR: 'Please provide details',
  INCIDENTS_MODAL_NEW_INCIDENT_CREATE_BUTTON_LABEL: 'Create',
  INCIDENTS_MODAL_STATUS_LOG_DESCRIPTION:
    'Historical record of status changes.',
  INCIDENTS_MODAL_STATUS_LOG_STATUS_PREFIX: 'Incident',
  INCIDENTS_MODAL_STATUS_OPEN: 'Open',
  INCIDENTS_MODAL_STATUS_REOPENED: 'Reopened',
  INCIDENTS_MODAL_STATUS_CLOSED: 'Closed',
  INCIDENTS_MODAL_STATUS_CLOSED_RESOLVED: 'Closed - Resolved',
  INCIDENTS_MODAL_STATUS_CLOSED_UNRESOLVED: 'Closed - Unresolved',
  INCIDENTS_MODAL_VIEW_LOG_DESCRIPTION: 'Historical record of views.',

  INCIDENTS_BUTTON_MANAGE_TAGS_LABEL: 'Manage Tags',
  INCIDENTS_BUTTON_NEW_INCIDENT_LABEL: 'New Incident',
  INCIDENTS_TAGS_TITLE: 'Tags',
  INCIDENTS_ADD_TAGS_LINK_LABEL: 'Add Tags',
  INCIDENTS_ADD_TAGS_MODAL_TITLE: 'Add Tags',
  INCIDENTS_ADD_TAGS_MODAL_DESCRIPTION: 'Select tags for quick search',
  INCIDENTS_CLOSE_INCIDENT_MODAL_TITLE: 'Close Incident',
  INCIDENTS_CLOSE_INCIDENT_MODAL_DESCRIPTION:
    'Select an applicable status and add a final analysis to close the incident',
  INCIDENTS_CLOSE_INCIDENT_MODAL_CLOSE_DATE_TITLE: 'Close Date',
  INCIDENTS_CLOSE_INCIDENT_MODAL_CLOSE_TIME_TITLE: 'Close Time',
  INCIDENTS_CLOSE_INCIDENT_MODAL_CLOSE_TIMEZONE_TEXT: 'Timezone:',
  INCIDENTS_CLOSE_INCIDENT_MODAL_FINAL_ANALYSIS_TITLE: 'Final Analysis',
  INCIDENTS_CLOSE_INCIDENT_MODAL_CONFIRM_BUTTON_LABEL: 'Confirm',
  INCIDENTS_CLOSE_INCIDENT_MODAL_CANCEL_BUTTON_LABEL: 'Cancel',
  INCIDENTS_REOPEN_INCIDENT_MODAL_TITLE:
    'Are you sure you want to reopen this incident?',
  INCIDENTS_REOPEN_INCIDENT_MODAL_DESCRIPTION:
    'The status of the incident "$incidentSummary" will be changed to "Open".',
  INCIDENTS_REOPEN_INCIDENT_DEFAULT_NOTE: 'Incident reopened.',
  INCIDENTS_EDIT_TAGS_MODAL_TITLE: 'Edit Tags',
  INCIDENTS_STATUS_LOG_MODAL_TITLE: 'Status Log',
  INCIDENTS_STATUS_LOG_MODAL_BACK_BUTTON_LABEL: 'Back',
  INCIDENTS_VIEW_LOG_MODAL_TITLE: 'View Log',
  INCIDENTS_EDIT_TAGS_LINK_LABEL: 'Edit Tags',
  INCIDENTS_VIEW_STATUS_LOG_LINK_LABEL: 'View Status Log',
  INCIDENTS_MANAGE_TAGS_MODAL_DESCRIPTION:
    'Delete an existing tag or create a new tag',
  INCIDENTS_MANAGE_TAGS_MODAL_ENTER_TAG_NAME_PLACEHOLDER: 'Enter tag name',
  INCIDENTS_MANAGE_TAGS_MODAL_VALID_TAG_NAME_ERROR: 'Enter a valid tag name',
  INCIDENTS_MANAGE_TAGS_MODAL_TAG_ALREADY_EXISTS: 'This tag already exists',
  INCIDENTS_MANAGE_TAGS_MODAL_SAVE_BUTTON_LABEL: 'Save',
  INCIDENTS_MANAGE_TAGS_MODAL_CANCEL_BUTTON_LABEL: 'Cancel',
  INCIDENTS_PENDING_CHANGES_MODAL_TITLE: 'You have unsaved changes',
  INCIDENTS_PENDING_CHANGES_MODAL_SAVE_CHANGES_BUTTON_LABEL: 'Save Changes',
  INCIDENTS_PENDING_CHANGES_MODAL_DISCARD_BUTTON_LABEL: 'Discard',
  INCIDENTS_PENDING_CHANGES_MODAL_CONFIRMATION_MESSAGE:
    'It looks like you have some unsaved changes. Would you like to save these changes?',
  INCIDENTS_FILTER_BY_EVIDENCE_TYPE_MODAL_TITLE: 'Filter by Evidence Type',
  INCIDENTS_FILTER_BY_EVIDENCE_TYPE_MODAL_APPLY_BUTTON_LABEL: 'Apply',
  INCIDENTS_FILTER_BY_EVIDENCE_TYPE_MODAL_CANCEL_BUTTON_LABEL: 'Cancel',
  DEVICES_PAGE_TITLE: 'Devices',
  NEW_DEVICES_PAGE_TITLE: 'Add device',
  EDIT_DEVICES_PAGE_TITLE: 'Manage device',
  LICENSES_PAGE_TITLE: 'Licenses',
  LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_NAME: 'Name',
  LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_LICENSE_KEY: 'License Key',
  LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_STATUS: 'Status',
  LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_ASSIGNED_DEVICE: 'Assigned Device',
  LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_LOCATION: 'Location',
  LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_START_DATE: 'Start Date',
  LICENSES_PAGE_GRID_COLUMN_HEADER_DISPLAY_END_STATE: 'End Date',
  LICENSES_PAGE_GRID_ASSIGNED_DEVICE_LOCATION_FIELD_DEFAULT: 'Not Assigned',
  LICENSES_PAGE_GRID_DATE_FIELD_DEFAULT: 'N/A',
  LICENSES_PAGE_GRID_LICENSE_STATUS_AVAILABLE: 'Available',
  LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVE: 'Active',
  LICENSES_PAGE_GRID_LICENSE_STATUS_ATTACH_LICENSE: 'Attach License',
  LICENSES_PAGE_GRID_LICENSE_STATUS_ACTIVATION_PENDING: 'Active on',
  LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRED: 'Expired',
  LICENSES_PAGE_GRID_LICENSE_STATUS_EXPIRING_SOON: 'Expiring Soon',
  LICENSES_SUMMARY_PAGE_TITLE: 'Purchase Summary',
  PRODUCTS_PAGE_TITLE: 'Plans and Pricing for Duclo Connect',
  PRODUCTS_PAGE_DURATION_MONTH: 'Month',
  PRODUCTS_PAGE_DURATION_YEAR: 'Year',
  PRODUCTS_PAGE_ADD_SERVICE_BUTTON: 'Add Service',
  PRODUCTS_PAGE_MULTIYEAR_CLOUD_ACCESS: 'Multi-Year Cloud Access',
  PURCHASE_PAGE_TITLE: 'Purchase',
  PURCHASE_SUMMARY_PAGE_TITLE: 'Purchase Summary',
  PURCHASE_CONFIRMATION_PAGE_TITLE: 'Purchase Confirmation',
  PURCHASE_CONFIRMATION_CARD_TITLE: 'Thank you for your payment',
  PURCHASE_CONFIRMATION_CARD_HEADLINE:
    'Your new licenses will be ready shortly.',
  PURCHASE_PRODUCT_NAME_TITLE: 'Product Name',
  PURCHASE_QUANTITY_TITLE: 'Qty',
  PURCHASE_AMOUNT_TITLE: 'Amount',
  PURCHASE_REMOVE_LINK_TITLE: 'Remove',
  PURCHASE_SUBTOTAL_TITLE: 'Subtotal',
  PURCHASE_TAX_AND_FEES_TITLE: 'Tax & Additional Fees',
  PURCHASE_TAX_AND_FEES_INVOICE_TITLE: 'Will be added in invoice',
  PURCHASE_TOTAL_DUE_TITLE: 'Total Due',
  PAGE_NOT_FOUND_TITLE: '404 - Page Not Found',
  PAGE_COMING_SOON_TITLE: 'Coming Soon.',
  NOT_AUTHORIZED_CONTENT: "You're not authorized to this content",
  ACCOUNT_VERIFY_PAGE_TITLE:
    'We’ve sent you an activation code to your registered email address.',
  ACCOUNT_VERIFY_ACTIVATION_CODE_LABEL: 'Enter Activation Code',
  ACCOUNT_VERIFY_SETUP_PASSWORD_LABEL: 'Setup Your Password',
  ACCOUNT_VERIFY_PASSWORD_REQUIREMENTS_LABEL: 'Password Requirements:',
  ACCOUNT_VERIFY_SUCCESS_MESSAGE:
    'We have sent you an email with new invitation code, please check your email.',
  ACCOUNT_VERIFY_LOGIN_PAGE_SUCCESS_MESSAGE:
    'Your account is verified successfully. Please try to Login.',
  ACCOUNT_VERIFY_ERROR_MESSAGE:
    'There is some technical issue, please try again or contact support.',
  ACCOUNT_VERIFY_ATLEAST_EIGHT_CHARS: 'At least 8 characters',
  ACCOUNT_VERIFY_ATLEAST_ONE_UPPERCASE: 'At least one upper case letter',
  ACCOUNT_VERIFY_ATLEAST_ONE_LOWERCASE: 'At least one lower case letter',
  ACCOUNT_VERIFY_ATLEAST_ONE_NUMBER: 'At least one number (0–9)',
  ACCOUNT_VERIFY_ATLEAST_ONE_SPECIAL_CHAR:
    'At least one special character (#$%^&*@!)',
  ACCOUNT_VERIFY_SUBMIT_BUTTON: 'Complete Account Activation',
  ACCOUNT_VERIFY_RESENDCODE_HEADING: 'Didn’t get the code?',
  ACCOUNT_VERIFY_RESENDCODE_LINK_TITLE: 'Click to resend',
  ACCOUNT_VERIFY_HELP_LINK_TITLE: 'Need Help?',
  ACCOUNT_NOT_ENABLED_TITLE: 'Account Not Enabled',
  ACCOUNT_NOT_ENABLED_SUBTITLE1: 'Your account has not been enabled on ',
  ACCOUNT_NOT_ENABLED_SUBTITLE2: 'Please contact your system administrator.',
  ACCOUNT_NOT_ACTIVATED_TITLE: 'Account Not Activated',
  ACCOUNT_NOT_ACTIVATED_SUBTITLE: 'Your account has not been verified yet. Please activate your account by verifying your email address, then log in again.',
  PRODUCTS_PAGE_HIGHLIGHTS:
    'Securely Access and view end-to-end encrypted live and historical video from anywhere, anytime. - Quickly find and retrieve historical video footage using intelligent, natural language-based search tools. - Get notified of relevant events in real-time with easy to set up, AI-based rules. - Remotely manage your Organization’s security devices and operations. Be alerted to issues in real time and troubleshoot problems from your device. - Easily manage and collaborate on security and operations incidents. - Mobile-first: Access all Duclo Connect features on your browser or mobile device via the Connect App.',
  PRODUCTS_PAGE_OVERVIEW:
    'Connect access plans enable you to connect, access and manage your Organization’s video devices through Duclo’s secure, mobile-first cloud platform:',
  PURCHASE_SUMMARY_PAGE_OVERVIEW:
    'Please review your purchase selections.  By making your purchase, you agree to Duclo\'s Privacy Policy and Terms & Conditions.  Also - Please change "Will be added in invoice" to "To be calculated at Purchase"',
  PURCHASE_CONFIRMATION_PAGE_OVERVIEW:
    'Once your new licenses are generated, we will send a copy of your receipt to the email address on file, with instructions to activate them.',
  LIVE_STREAM_OVERLAY_LABEL: 'Live',
  LIVE_STREAM_GO_LIVE_BUTTON: 'Go Live',
  ORG_DASHBOARD_TECHNICIANS_NOT_FOUND: 'No Technicians Assigned',
  ORG_DASHBOARD_PARTNER_NOT_FOUND: 'No Partner Assigned',
  ORG_DASHBOARD_USER_NOT_FOUND: 'No Users Added',
  ORG_DASHBOARD_DEVICES_OFFLINE_CAMERAS_TROUBLESHOOT_SECTION_TITLE:
    'Troubleshoot',
  NAME_REQUIRED_ERROR_MESSAGE: '*Name is required',
  ORGANIZATION_NAME_REQUIRED_ERROR_MESSAGE: '*Organization Name is required',
  EMAIL_REQUIRED_ERROR_MESSAGE: '*Email is required',
  EMAIL_VALID_ERROR_MESSAGE: 'Enter valid email address',
  PASSWORD_ERROR_MESSAGE: 'Password is required',
  CONFIRM_PASSWORD_ERROR_MESSAGE: 'Confirm Password is required',
  CONFIRM_PASSWORD_NOT_MATCH_ERROR_MESSAGE: 'Password not matching',
  INVITATION_ID_ERROR_MESSAGE: '*Please enter a valid 6-digit code.',
  ONLY_ALPHABETS_ERROR_MESSAGE: 'Enter alphabets only',
  ONLY_ALPHA_NUMBERIC_ERROR_MESSAGE: 'Enter alpha numeric only',
  CANT_LONGER_ERROR_MESSAGE: "*This field can't be longer than 32 characters",
  VALID_PHONE_NUMBER_ERROR_MESSAGE:
    "*Please enter a valid phone number and can't longer than 11 digits",
  PHONE_NUMERIC_ALPHA_ERROR : "Phone number must be numeric.",
  ASSIGN_CUSTOMER_SELECT_ONE_ERROR_MESSAGE:
    'Please select atleast one customer organization to assign.',
  ACCOUNT_NOT_AVAILABLE_ERROR_MESSAGE: 'User Id is not available.',
  ADDRESS_REQUIRED_ERROR_MESSAGE: '*Address is required',
  ADDRESS_FIELD_ERROR_MESSAGE:
    '*Please enter a complete address. Street, City, State, and ZipCode.',
  WEBSITE_URL_REQUIRED_ERROR_MESSAGE: '*Website URL is required',
  WEBSITE_CORRECT_URL_MESSAGE: '*Enter correct URL',
  AREA_NAME_REQUIRED_ERROR_MESSAGE: 'Area name is required',
  CUSTOMER_NEW_USER_ASSIGN_LOCATIONS_BUTTON_TITLE:
    'Assign All Locations & Areas',
  CUSTOMER_NEW_USER_ASSIGN_LOCATIONS_POPUP_TITLE: 'Assign Location',
  ADD_DEVICE_SERIAL_REQUIRED: '*Serial Number is required',
  ADD_DEVICE_MAC_ADDRESS_INVALID: 'Invalid MAC Address',
  ADD_DEVICE_MAC_REQUIRED: '*Mac address is required',
  ADD_DEVICE_MANUFACTURER_REQUIRED: '*Manufacturer is required',
  ADD_DEVICE_MODEL_REQUIRED: '*Model is required',
  DEVICES_SWITCH_TIMELINE_TITLE: 'TIMELINE',
  DEVICES_SWITCH_LIVE_TITLE: 'LIVE',
  DEVICES_CLAIMED_DEVICE_STATUS: 'CLAIMED',
  DEVICES_DEACTIVATED_DEVICE_STATUS: 'DEACTIVATED',
  DEVICES_ENTERED_DEVICE_STATUS: 'ENTERED',
  DEVICES_REGISTERED_DEVICE_STATUS: 'REGISTERED',
  DEVICES_PENDING_CLAIM_DEVICE_STATUS: 'PENDING_CLAIM',
  DEVICES_OFFLINE_CONNECTION_STATUS: 'offline',
  DEVICES_ONLINE_CONNECTION_STATUS: 'online',
  DEVICES_RETURN_OFFLINE_STATUS: 'Offline',
  DEVICES_RETURN_ONLINE_STATUS: 'Online',
  DEVICES_RETURN_READY_TO_CLAIM_STATUS: 'Ready To Claim',
  DEVICES_RETURN_DEACTIVATED_STATUS: 'Deactivated',
  DEVICES_RETURN_ENTERED_STATUS: 'Entered',
  DEVICES_RETURN_CLAIMING_STATUS: 'Claiming',
  DEVICES_ADD_DEVICE_TITLE: 'Add Device',
  DEVICES_STEP_ONE_SCAN_CONTENT:
    'Scan a QR code or manually enter the device information to add your device.',
  DEVICES_STEP_ONE_OF_THREE: 'Step 1 of 3',
  DEVICES_ENTER_INFORMATION: 'Enter Device Information',
  DEVICES_STEP1_INFO:
    'Look for the information on the device, packaging, or user manual.',
  DEVICES_STEP_ONE_PROCESS_LATER:
    'You may add the device to the organization and complete the activation process later.',
  DEVICES_SETUP_LATER_TEXT: 'Complete Setup Later',
  DEVICES_CONTINUE_TEXT: 'Continue',
  DEVICES_SELECT_MODEL_TEXT: 'Select Model',
  DEVICES_DEVICE_NAME_TEXT: 'Device Name',
  DEVICES_DEVICE_NAME_PLACEHOLDER: 'Name Device (e.g.) Front Door',
  DEVICES_SELECT_MANUFACTURER_TEXT: 'Select Manufacturer',
  DEVICES_MANUFACTURER_TEXT: 'Manufacturer',
  DEVICES_MODEL_TEXT: 'Model',
  DEVICES_MODEL_CLOUD_BRIDGE: 'CLOUD BRIDGE',
  DEVICES_SERIAL_NO_TEXT: 'Serial Number',
  DEVICES_MAC_ADDRESS_TEXT: 'Mac Address',
  DEVICES_STEP_TWO_CONFIRM_TEXT: 'Confirm the device information bellow.',
  DEVICES_STEP_TWO_OF_THREE: 'Step 2 of 3',
  DEVICES_STEP2_RE_INFO: 'Re-enter Device Information',
  DEVICES_STEP3_CONFIRM_TEXT: 'Confirm the device information bellow.',
  DEVICES_STEP_THREE_OF_THREE: 'Step 3 of 3',
  DEVICES_ASSIGN_LOCATION_AREA_TEXT: 'Assign Location & Area',
  DEVICES_COMPLETE_DEVICE_SETUP: 'Complete Device Setup',
  DEVICES_SAVE_CHANGES_BUTTON: 'Save Changes',
  DEVICES_REMOVE_DEVICE_BUTTON: 'Remove Device',
  DEVICES_CLAIM_DEVICE_BUTTON: 'Claim Device',
  DEVICES_ATTACH_ALL_LICENSES: 'Attach All Licenses',
  DEVICES_FIRMWARE_UPGRADE_AVAILABLE: 'Firmware Update Available',
  DEVICES_ATTACH_ALL_DEVICES: 'Attach All Devices',
  DEVICES_ATTACH_LICENSE_BUTTON: 'Attach New License',
  DEVICES_CLAIM_ALL_DEVICES: 'Claim All Devices',
  DEVICES_BACK_TO_DEVICES: 'Back to devices',
  DEVICES_REFRESH_BROWSER_TEXT: 'Please refresh your browser and try again.',
  DISCOVERED_DEVICES_MESSAGE:'You have new devices to onboard',
  DISCOVERED_DEVICES_PENDING_COUNT:' Pending Devices',
  DISCOVERED_DEVICES_SETUP_BUTTON:'Onboard Devices',
  DISCOVERED_DEVICES_ADD_DEVICES:'Add Devices',
  DISCOVERED_DEVICES_REMOVE_DEVICE:'Remove Device',
  DISCOVERED_DEVICES_ERROR_MESSAGE:'Unable to setup Area/Location/Device Name, please try again. If you continue to experience an error please contact support.',
  DISCOVERED_DEVICES_SETUP_ERROR_MESSAGE:'Unable to setup, please try again. If you continue to experience an error please contact support.',
  DISCOVERED_DEVICES_LOAD_MORE: 'Load More',
  DISCOVERED_DEVICES_REMOVE_DEVICES:'Remove Devices',
  DISCOVERED_DEVICES_RECLAIM: 'Reclaim',
  DISCOVERED_DEVICES_ADD: 'Add',
  DISCOVERED_DEVICES_SAVE: 'Save',
  DISCOVERED_DEVICES_NEXT: 'Next',
  DISCOVERED_DEVICES_YES_ADD_DEVICES: 'Yes, Add Devices',
  DISCOVERED_DEVICES_SKIP_STEP: 'Skip Step',
  DISCOVERED_DEVICES_NO_DEVICE_MESSAGE:'You currently have no devices to setup.',
  DISCOVERED_DEVICES_BILK_ACTIONS:'Bulk Actions ',
  DISCOVERED_DEVICES_SET_LOC_AREA:'Set Location & Area',
  DISCOVERED_DEVICES_DELETE_MESSAGE:'Are you sure you want to delete all selected devices? This action will remove them from the system.',
  DISCOVERED_DEVICES_DELETE_CANCEL: 'Cancel',
  DISCOVERED_DEVICES_DELETE: 'Delete',
  DISCOVERED_DEVICES_RECLAIM_TITLE:'Reclaim Camera',
  DISCOVERED_DEVICES_RECLAIM_MESSAGE:'This camera was previously connected to your account. What would you like to do?',
  DISCOVERED_DEVICES_RECLAIM_OPT1_TITLE:'Re-attach Camera to Account',
  DISCOVERED_DEVICES_RECLAIM_OPT1_INFO:'This will maintain your camera’s previous settings and recordings.',
  DISCOVERED_DEVICES_RECLAIM_OPT2_TITLE:'Setup As New Device',
  DISCOVERED_DEVICES_RECLAIM_OPT2_INFO:'You will still have access to historical recordings that were captured by this camera.',
  DISCOVERED_DEVICES_RECLAIM_CONFIRM:'Confirm',
  DISCOVERED_DEVICES_CANCEL:'Cancel',
  DISCOVERED_DEVICES_SETUP:'Device Setup',
  DISCOVERED_DEVICES_BACK:'Back',
  DISCOVERED_DEVICES_DISABLE_BULK_TOOLTIP:'Must have more than one device selected to enable this feature',
  DISCOVERED_DEVICES_SELECT_LOCATION_TOOLTIP:'Select a location first to enable device area',
  DISCOVERED_DEVICES_SELECT_LOCATION_AREA_TOOLTIP:'Set a location and area to enable device claiming',
  DISCOVERED_DEVICES_SELECT_LOCATION_NVR_TOOLTIP:'Set a location to enable device claiming',
  DISCOVERED_DEVICES_OFFLINE_DEVICE_TOOLTIP:'You cannot claim while the device is offline.',
  DISCOVERED_DEVICES_SEARCH_PLACEHOLDER:'Search Devices or Servers',
  DISCOVERED_DEVICES_NAME_PLACEHOLDER:'Device Name',
  DISCOVERED_DEVICES_SET_LOCATION:'Set Location',
  DISCOVERED_DEVICES_SET_AREA:'Set Area',
  DISCOVERED_DEVICES_ADD_MESSAGE:'Do you want to add all the selected devices under the chosen location and area?',
  DEVICES_TAB_DEVICE_INFO: 'Device Information',
  DEVICES_TAB_VIDEO_SETTINGS: 'Video Settings',
  DEVICES_TAB_FOCUS: 'Focus',
  DEVICES_TAB_AUDIO_SETTINGS: 'Audio Settings',
  DEVICES_TAB_LICENSES: 'Licenses',
  DEVICES_TAB_NETWORK: 'Network',
  DEVICES_TAB_DEVICE_PASSWORD: 'Device Management',
  DEVICES_TAB_ANALYTICS_SETTINGS: 'Analytics Settings',
  SELECT_FIELD_DEFAULT_VALUE_LABEL: 'Video Settings',
  UNABLE_TO_SAVE_DEVICE_SETTINGS_HEADING: 'Unable to edit video settings.',
  UNABLE_TO_SAVE_DEVICE_SETTINGS_MESSAGE:
    'You are unable edit the video settings while your camera is offline.',
  DEVICES_TAB_LICENSES_ATTACH_NEW_LICENSE_LABEL: 'Attach New License',
  DEVICES_TAB_LICENSES_MODAL_TITLE: 'Attach a new license for $deviceName',
  DEVICES_TAB_LICENSES_DETACH_LICENSE_MODAL_TITLE:
    'Are you sure you want to detach "$licenseName" from your device?',
  DEVICES_TAB_LICENSES_DETACH_LICENSE: 'Detach License',
  DEVICES_TAB_LICENSES_LICENSE_TYPE: 'License Type',
  DEVICES_TAB_LICENSES_LICENSE_EXPIRATION: 'License Expiration',
  DEVICES_TAB_LICENSES_LICENSE_START_DATE: 'Start Date',
  DEVICES_TAB_LICENSES_LICENSE_EXPIRATION_DATE: 'Expiration Date',
  DEVICES_TAB_LICENSES_NO_LICENSE_ATTACHED: 'No License Attached',
  NO_LICENSE_TITLE: 'No License Found',
  DEVICES_TAB_LICENSES_NO_LICENSE_AVAILABLE:
    'You currently have no license under the organization account. Please purchase license to continue.',
  DEVICES_TAB_LICENSES_PURCHASE_LICENSE: 'Purchase License',
  DEVICES_TAB_VIDEO_SETTINGS_RESOLUTION_PLACEHOLDER: 'Select Resolution',
  DEVICES_TAB_VIDEO_SETTINGS_FLIP_LABEL: 'Flip',
  DEVICES_TAB_VIDEO_SETTINGS_MIRROR_LABEL: 'Mirror',
  DEVICES_TAB_VIDEO_SETTINGS_DEGREES_PLACEHOLDER: 'Select Degrees',
  DEVICES_TAB_VIDEO_SETTINGS_WDR_LEVEL_LABEL: 'WDR Level',
  DEVICES_TAB_VIDEO_SETTINGS_WISE_STREAM_LABEL: 'Wisestream',
  DEVICES_TAB_VIDEO_SETTINGS_DYNAMIC_GOV_LABEL: 'Dynamic GOV',
  DEVICES_TAB_VIDEO_SETTINGS_DYNAMIC_FPS_LABEL: 'Dynamic FPS',
  DEVICES_TAB_VIDEO_SETTINGS_MINIMUM_FPS_LABEL: 'Minimum FPS',
  DEVICES_TAB_VIDEO_SETTINGS_BRIGHTNESS_LABEL: 'Brightness',
  DEVICES_TAB_VIDEO_SETTINGS_SHARPNESS_LABEL: 'Sharpness',
  DEVICES_TAB_VIDEO_SETTINGS_CONTRAST_LABEL: 'Contrast',
  DEVICES_TAB_VIDEO_SETTINGS_AUTO_IR_MODE_LABEL: 'Auto IR Mode',
  DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_TITLE: 'Device Restart Required',
  DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CONTENT:
    'Your device will be restarted in order to apply this change. During the process, no video will be recorded to the cloud. Would you like to proceed with the change now?',
  DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CONFIRM_BUTTON_TITLE: 'Confirm',
  DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE: 'Cancel',
  UNABLE_TO_SAVE_DEVICE_INFO_HEADING: 'Device error',
  UNABLE_TO_SAVE_DEVICE_INFO_MESSAGE:
    'We’ve encountered an error, visit our Help page to troubleshoot.',
  DEVICE_INFO_MEETING_ROOM_TITLE: 'Meeting Room',
  DEVICE_INFO_DEVICE_STATUS_TITLE: 'Status',
  DEVICE_INFO_DEVICES_MAC_ADDRESS_TITLE: 'MAC Address',
  DEVICE_INFO_FIRMWARE_TITLE: 'Device Firmware',
  DEVICE_INFO_DATE_TIME_TITLE: 'Date & Time',
  DEVICE_INFO_ASSIGN_APPLICATION: 'Assign Applications',
  DEVICE_INFO_SELECT_APPLICATION: 'Select Application',
  DEVICE_INFO_ASSIGN_LOCATIONS_TITLE: 'Assign Location Area',
  DEVICE_INFO_CURRENT_TIME_TITLE: 'Current System Time',
  DEVICES_TAB_NETWORK_IP_TYPE_TITLE: 'IP Type',
  DEVICES_TAB_NETWORK_IP_ADDRESS_TITLE: 'IP Address',
  DEVICES_TAB_NETWORK_SUBNET_MASK_TITLE: 'Subnet Mask',
  DEVICES_TAB_NETWORK_DNS1_TITLE: 'DNS1',
  DEVICES_TAB_NETWORK_DNS2_TITLE: 'DNS2',
  MIN_VALUE_LABEL: 'Low',
  MEDIUM_VALUE_LABEL: 'Medium',
  MAX_VALUE_LABEL: 'High',
  DEVICE_PASSWORD_TAB_TITLE: 'Device Management',
  DEVICE_PASSWORD_TAB_RESTART_DEIVCE_BUTTON_TITLE: 'Restart Device',
  DEVICE_PASSWORD_TAB_FACTORY_RESET_BUTTON_TITLE: 'Factory Reset',
  DEVICE_PASSWORD_TAB_RECLAIM_DEVICE_BUTTON_TITLE: 'Reclaim Device',
  DEVICE_PASSWORD_TAB_REMOVE_DEVICE_MODAL_BUTTON_TITLE: 'Remove This Device',
  DEVICE_PASSWORD_TAB_REMOVE_DEVICE_BUTTON_TITLE:
    'Remove Device From Organization',
  DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_TITLE:
    'Are you sure you want to remove this device?',
  DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_CONTENT:
    'By continuing, you will no longer have access to the device and all of its content will be completely removed from the system.',
  DEVICE_PASSWORD_TAB_REMOVE_DEVICE_UNABLE_TO_REMOVE_MODAL_TITLE:
    'Unable to remove the device.',
  DEVICE_PASSWORD_TAB_REMOVE_DEVICE_UNABLE_TO_REMOVE_MODAL_CONTENT:
    'There is an active license associated with this device. Please detach the license before removing this device.',
  DEVICE_REMOVED_TITLE: 'Device Removed',
  DEVICE_REMOVED_MODAL_CONTENT:
    'It may take up to 48 hours to completely remove all historical videos for this device.',
  DEVICE_NOT_FOUND: 'No Device Found',
  DEVICE_NOT_ADDED: 'You currently have no devices added to this account.',
  UNABLE_TO_REMOVE_DEVICE_TITLE: 'Unable to remove device.',
  TRY_AGAIN_TITLE: 'Please try again.',
  TRY_AGAIN_BUTTON_TITLE: 'Try Again',
  FACTORY_RESET_MODAL_TITLE:
    'Are you sure you want to factory reset this device?',
  FACTORY_RESET_MODAL_CONTENT:
    'Once the factory reset is complete, you will need to ensure the Duclo firmware is installed. Once that is confirmed, you will need to reclaim this device.',
  FACTORY_RESET_MODAL_SUB_CONTENT:
    'Your video recording will be interrupted until the device is reclaimed.',
  RESET_FACTORY_DEFAULT_BUTTON_TITLE: 'Reset to Factory Default',
  DEVICE_RECLAIM_IN_PROGRESS_TITLE: 'Device reclaiming in progress.',
  DEVICE_PASSWORD_TAB_RECLIAM_MODAL_CONTENT:
    'Please ensure the device is online and the latest version of the Duclo firmware is installed.  If the device status remains as "Claiming..." for more than 5 minutes, restart the device".',
  BACK_TO_DEVICE_MANAGEMENT_TITLE: 'Back to Device Management',
  DEVICE_CLAIMED_TITLE: 'Device Claimed',
  RESTART_DEVICE_MODAL_TITLE: 'Are you sure you want to restart this device?',
  RESTART_DEVICE_MODAL_CONTENT:
    'Your video recording will be interrupted while the device is rebooting.',
  CANCEL_BUTTON_TITLE: 'Cancel',
  DEVICES_TAB_AUDIO_SETTINGS_AUDIO_IN_LABEL: 'Audio In',
  DEVICES_TAB_AUDIO_SETTINGS_AUDIO_OUT_LABEL: 'Audio Out',
  DEVICES_TAB_AUDIO_SETTINGS_AUDIO_IN_SEARCH_LABEL:
    'Source: Internal Microphone',
  DEVICES_TAB_AUDIO_SETTINGS_VOLUME_LABEL: 'Volume',
  DEVICES_TAB_AUDIO_SETTINGS_SENSITIVITY_LABEL: 'Sensitivity',
  DEVICES_TAB_AUDIO_SETTINGS_NOISE_REDUCTION_LABEL: 'Noise Reduction',
  UNABLE_TO_SAVE_AUDIO_SETTINGS_HEADING: 'Unable to edit audio settings.',
  UNABLE_TO_SAVE_AUDIO_SETTINGS_MESSAGE:
    'You are unable edit the audio settings while your camera is offline.',
  NAME_TABLE_HEADER: 'Name',
  CAMERAS_OFFLINE_TABLE_HEADER: 'Cameras Offline',
  LICENSES_EXPIRED_TABLE_HEADER: 'Licenses Expired',
  LICENSES_EXPIRING_TABLE_HEADER: 'Licenses Expiring',
  EMAIL_TABLE_HEADER: 'Email',
  PHONE_TABLE_HEADER: 'Phone Number',
  ONLINE_TABLE_HEADER: 'Online',
  ROLE_TABLE_HEADER: 'Role',
  LAST_ACTIVE_TABLE_HEADER: 'Last Active',
  LAST_UPDATED_TABLE_HEADER: 'Last updated',
  ADMIN_USER_ROLE_LABEL: 'Admin',
  NORMAL_USER_ROLE_LABEL: 'User',
  ACTIVE_USER_STATUS_LABEL: 'Active',
  INACTIVE_USER_STATUS_LABEL: 'Inactive',
  NOT_ACTIVE_USER_STATUS_LABEL: 'Not Active',
  PENDING_APPROVAL_STATUS_LABEL: 'Pending Invite',
  DISABLED_STATUS_LABEL: 'Disabled',
  CUSTOMER_NAME_FIELD: 'Customer Name',
  ORGANIZATION_NAME_FIELD:'Organization Name',
  FIRST_NAME_FIELD: 'First Name',
  LAST_NAME_FIELD: 'Last Name',
  PHONE_NUMBER_FIELD: 'Phone Number',
  EMAIL_ADDRESS_FIELD: 'Email Address',
  WEBSITE_NAME_FIELD: 'Website',
  LINKEDIN_PROFILE_NAME_FIELD: 'LinkedIn Profile',
  ADMINISTRATOR_FIELD: 'Administrator',
  ASSIGN_LOCATIONS_AREAS_FIELD: 'Assign Locations & Areas',
  ASSIGN_CUSTOMERS_FIELD: 'Assign Organization',
  ACCOUNT_STATUS_FIELD: 'Account Status',
  LOCATION_NAME_FIELD: 'Location Name',
  CREATE_DEFAULT_AREA_FIELD: 'Main Area (Default)',
  CREATE_NEW_AREA_FIELD: 'Add new area',
  ENTER_AREA_NAME_FIELD: 'Enter area name',
  ADDRESS_FIELD: 'Address',
  CREATE_PASSWORD_FIELD: 'Create Password',
  CONFIRM_PASSWORD_FIELD: 'Confirm Password',
  FOOTER_NAV_TERMS_CONDITION: 'Terms of Service',
  FOOTER_NAV_PRIVACY_POLICY: 'Privacy Policy',
  NO_RECORDS_FOUND_TITLE: 'No records found.',
  ERROR_TOAST_TITLE: 'Error',
  SUCCESS_TOAST_TITLE: 'Success',
  INVALID_MAC_ADDRESS: 'Invalid MAC Address',
  SCAN_QR_CODE: 'Scan a QR Code',
  HOLD_CAMERA_LABEL: 'Hold your camera near the QR code.',
  VALIDATION_MESSAGE:
    'The device $device is not currently supported.Please send a request if you need this camera to work within the Duclo platform',
  DEVICE_INFO_DUCLO_FIRMWARE: 'Duclo Firmware',
  UPDATE_AVILABLE_TITLE: 'Update available',
  MAC_ADDRESS_FORMATE: 'Format AA:BB:CC:DD:EE:FF',
  CONFIRM_DEVICE_INFORMATION: 'Confirm the device information bellow.',
  DEVICE_CLAIMING_TEXT: 'Device Claiming in Progress',
  DEVICE_STATUS_MEESAGE:
    'Please ensure the device is online and the latest version of the Duclo firmware is installed.  If the device status remains as "Claiming..." for more than 5 minutes, restart the device',
  ADD_ANOTHER_CAMERA: 'Add Another Camera',
  BACK_TO_DEVICES: 'Back To Devices',
  CLAIM_DEVICE: 'Claim Device',
  ANALYTICS_SETTINGS: 'Analytics Settings',
  ADVANCED_ANALYTICS: 'Advanced Analytics',
  DEVICES_TAB_ANALYTICS_SETTINGS_VEHICLE_DETECTION: 'Vehicle Detection',
  DEVICES_TAB_ANALYTICS_SETTINGS_PERSON_DETECTION: 'Person Detection',
  DEVICES_TAB_ANALYTICS_SETTINGS_SHOCK_DETECTION: 'Shock Detection',
  DEVICES_TAB_ANALYTICS_SETTINGS_TAMPERING_DETECTION: 'Tampering Detection',
  DEVICES_TAB_ANALYTICS_SETTINGS_DEFOCUS_DETECTION: 'Defocus Detection',
  DEVICES_TAB_ANALYTICS_SETTINGS_AUDIO_DETECTION: 'Audio Detection',
  DEVICES_TAB_ANALYTICS_SETTINGS_MOTION_DETECTION: 'Motion Detection',
  DEVICES_TAB_ANALYTICS_SETTINGS_ACTIVITY_ZONE: 'Activity Zone(s)',
  DEVICES_TAB_ANALYTICS_SETTINGS_EXCIUDED_ZONE: 'Excluded Zone',
  DEVICES_TAB_ANALYTICS_SETTINGS_OCCUPANCY_QUEUE: 'Occupancy Queue(s)',
  DEVICES_TAB_ANALYTICS_SETTINGS_LINE_CROSSING: 'Line-Crossing(s)',
  DEVICES_TAB_ANALYTICS_BACK: 'Back',
  DEVICE_TAB_LEVEL_OF_DETECTION: 'Level of Detection',
  DEVICE_TAB_MINIMUM_DURATION: 'Minimum Duration (Seconds)',
  EDIT_LOCATIONS_REMOVE_MODAL_TITLE_MESSAGE:
    'Are you sure you want to remove the location $locationName?',
  EDIT_LOCATIONS_REMOVE_TITLE: 'Unable to remove the Location $locationName.',
  UPDATE_AVAILABLE_TITLE: 'Update available',
  HERE_LABEL: 'here',
  DEVICES_TAB_FOCUS_SETTINGS: 'Focus Settings',
  DEVICES_TAB_FOCUS_SETTINGS_SUB_TITLE:
    'Press the “-” or “+” button once to adjust incrementally. Press and hold to adjust continuously.',
  DEVICES_TAB_FOCUS_NEAR: 'Near',
  DEVICES_TAB_FOCUS_FAR: 'Far',
  DEVICES_TAB_FOCUS_ZOOM_TITLE: 'Zoom',
  DEVICES_TAB_ZOOM_IN: 'In',
  DEVICES_TAB_ZOOM_OUT: 'Out',
  DEVICES_SIMPLE_FOCUS: 'Simple Focus',
  DEVICES_SIMPLE_INITIALIZE: 'Initialize Focus',
  DEVICE_STATUS_MESSAGE:
    'Please ensure the device is online and the latest version of the Duclo firmware is installed.  If the device status remains as "Claiming..." for more than 5 minutes, restart the device".',
  PROPERTY_STATUS_TRUE: 'true',
  PROPERTY_STATUS_FALSE: 'false',
  DEVICE_VIDEO_CODEC: 'Recording Video Codec',
  SESSION_EXPIRE_MESSAGE: 'Your session has expired. Please log in again.',
  DUCLO_FIRMEARE_UPDATE: 'Duclo Firmware Update',
  FIRMWARE_UPDATE_MESSAGE:
    'Update available. Your firmware will be updated based on the day and time specified below.',
  AUTO_UPDATE_FIRMWARE_VERSION: 'Auto Update Duclo Firmware',
  CHOOSE_DAY: 'Choose Day',
  CHOOSE_TIME: 'Choose Time',
  HOURS_DIFFERENCE: 'Update will start within this 2-hour window.',
  UPDATE_FIRMWARE: 'Update Duclo Firmware',
  UPDATE_FIRMWARE_MANUAL: 'manual',
  UPDATE_FIRMWARE_AUTO: 'auto',
  UPDATE_FIRMWARE_ALL_DAYS: 'All',
  UPDATE_FIRMWARE_MULTIPLE: 'Multiple',
  DEVICE_STATE_RESTARTING_MESSAGE: 'Restarting camera stream, one moment.',
  DEVICE_STATE_UPDATE_FIRMWARE: 'Update in progress...',
  DEVICE_STATE_CAMERA_OFFLINE: 'The camera is offline.',
  SEARCH_DASHBOARD_SEARCH_TEXT: 'What can I help you find?',
  SEARCH_DASHBOARD_RECENT_EVENTS: 'Recent Events',
  SEARCH_DASHBOARD_SHOW_ALL: 'Show All',
  SEARCH_DASHBOARD_NOT_ENOUGH_DATA:
    'Not enough recent events to provide comparison.',
  SEARCH_DASHBOARD_TOP_EVENTS: 'Top Camera Event Comparison',
  SEARCH_DASHBOARD_SUGGESTED_LABEL:
    'Suggested searches based off recent events:',
  SEARCH_DASHBOARD_NO_RECENT_EVENTS:
    'There have not been any events recorded in the last 24 hours.',
  SEARCH_DASHBOARD_VS_YSETERDAY: 'VS Yesterday',
  SEARCH_DASHBOARD_VS_LAST: 'VS Last',
  SEARCH_DASHBOARD_TODAY_LABEL: 'Today:',
  SEARCH_DASHBOARD_EXPLORE: 'Explore what Duclo can do',
  SEARCH_DASHBOARD_SUPPORTED_LANG: 'Supported languages: English',
  SEARCH_DASHBOARD_TRY_SAYING: 'Try Saying or Searching:',
  SEARCH_DASHBOARD_RESULT_NOT_FOUND: 'No Result Found',
  SEARCH_DASHBOARD_RESULT_NOT_FOUND_MESSAGE:
    'We couldn’t find what you’re looking for. Try broadening your search or',
  SEARCH_DASHBOARD_CLICK_HERE: 'Click Here',
  SEARCH_DASHBOARD_TO_SEE: 'to see all events.',
  SEARCH_DASHBOARD_ALL_RESULTS: 'All Results',
  SEARCH_DASHBOARD_SEARCH_RESULTS: 'Search Results',
  SEARCH_TAGS_BY_PERSON: 'Search by Person',
  SEARCH_TAGS_BY_VEHICLE: 'Search by Vehicle',
  SEARCH_TAGS_SELECT_ONE_MORE: 'Select one or more filters.',
  SEARCH_SHOW_FACES: 'Show Only Faces',
  SEARCH_ENABLING_THIS_FEATURE:
    'Enabling this feature will disable all other tags',
  SEARCH_AGE: 'Age',
  SEARCH_YOUNG: 'Young',
  SEARCH_MIDDLE: 'Middle Aged',
  SEARCH_ADULT: 'Adult',
  SEARCH_SENIOR: 'Senior',
  SEARCH_GLASSES: 'Glasses',
  SEARCH_MASK: 'Mask',
  SEARCH_HAT: 'Hat',
  SEARCH_BAG: 'Bag',
  SEARCH_GENDER: 'Gender',
  SEARCH_MALE: 'Male',
  SEARCH_FEMALE: 'Female',
  SEARCH_PANT_COLOR: 'Pant Color',
  SELECT_UPTO_TWO_COLOR: 'Select up to two colors',
  SEARCH_SHIRT_COLOR: 'Shirt Color',
  SEARCH_ACCESSORIES: 'Accessories',
  SEARCH_SHOW_LISENCE_PLATE: 'Show License Plates',
  SEARCH_VEHICLE_TYPE: 'Vehicle Type',
  SEARCH_CAR: 'Car',
  SEARCH_TRUCK: 'Truck',
  SEARCH_BUS: 'Bus',
  SEARCH_MOTERCYCLE: 'Motorcycle',
  SEARCH_BICYCLE: 'Bicycle',
  SEARCH_EXTERIOR_COLOR: 'Exterior Color',
  SEARCH_APPLY: 'Apply',
  SEARCH_ERROR_MESSAGE_COLOR:
    'You can only select two colors. Please unselect one of the colors to change your selection.',
  SEARCH_ALL_TAGS: 'All Tags',
  ANALYTICS_DASHBOARD_DATE_TEXT:
    'The percentage of camera online within a one-hour period of time.',
  ANALYTICS_DASHBOARD_QUEUE_COUNT: 'Queue Count',
  ANALYTICS_DASHBOARD_COMPARE: 'Compare',
  ANALYTICS_DASHBOARD_PERCENT_CAPACITY: '% at capacity',
  ANALYTICS_DASHBOARD_QUEUES_NEARING: 'Queues Nearing Capacity',
  ANALYTICS_DASHBOARD_QUEUES_OVER: 'Queues At or Over Capacity',
  ANALYTICS_DASHBOARD_QUEUES_LEFT: 'Queue Left',
  ANALYTICS_DASHBOARD_QUEUES_OVER_LABEL: 'Queue Over',
  ANALYTICS_DASHBOARD__SECTION_TITLE_OCCUPANCY_SECTION: 'Occupancy Analysis',
  ANALYTICS_DASHBOARD_OCCUPANCY_SECTION_DESCRIPTION:
    'The total count of persons within a camera FOW over one-hour period of time.',
  ANALYTICS_DASHBOARD_OCCUPANCY_COUNTED: 'Total people counted',
  ANALYTICS_DASHBOARD__SECTION_TITLE_USERLOGIN_SECTION: 'User Logins',
  ANALYTICS_DASHBOARD_USER_LOGIN_SECTION_DESCRIPTION:
    'The total count of user logins within 1 hour increment.',
  ANALYTICS_DASHBOARD_USER_LOGIN_COUNTED: 'Total logins',
  SEARCH_TOTAL_COUNT_RESULTS: 'results',
  NOTIFICATION_SETTINGS: 'Notification Settings',
  NOTIFICATION_NO_DATA_TITLE: 'You have no notifications',
  NOTIFICATION_SETUP_NEW: 'Setup a New Notification',
  NOTIFICATION_TITLE: 'Notification',
  NOTIFICATION_MY_NOTIFICATION: 'My Notification',
  NOTIFICATION_SYSTEM_NOTIFICATION: 'System Notification',
  NOTIFICATION_EMAIL: 'Email',
  NOTIFICATION_PUSH: 'Push',
  NOTIFICATION_MARKETING_MATERIAL: 'Marketing Material',
  NOTIFICATION_NEW_DEVICES: 'New Devices added to your system',
  NOTIFICATION_NEW_FEATURES: 'New available features',
  NOTIFICATION_TRIGGER_EVENTS: 'Trigger Events',
  NOTIFICATION_CAMERAS: 'Cameras',
  NOTIFICATION_DURATION_WHEN: 'When',
  NOTIFICATION_USER: 'User',
  NOTIFICATION_TYPE: 'Type',
  NOTIFICATION_CREATE_NOTIFICATION_BACK: 'back',
  NOTIFICATION_CREATE_NOTIFICATION: 'Create Notification',
  NOTIFICATION_CREATE_NOTIFICATION_EVENT_TYPE: 'Select Event Type',
  NOTIFICATION_CREATE_NOTIFICATION_SYSTEM_EVENT: 'System Events',
  NOTIFICATION_CREATE_NOTIFICATION_SYSTEM_EVENT_TYPES:
    'People, Vehicle, Camera Status, Loitering, Gunshot, Explosion, Breaking Glass, Screaming.',
  NOTIFICATION_CREATE_NOTIFICATION_CUSTOM_EVENT: 'Custom Events',
  NOTIFICATION_CREATE_NOTIFICATION_CUSTOM_EVENT_TYPES:
    'Camera Specific Defined by Administration Such as, Specific Area, Lines, People Queues.',
  NOTIFICATION_CREATE_NOTIFICATION_EVENT_TRIGGER: 'Select Event Trigger',
  NOTIFICATION_CREATE_NOTIFICATION_EVENT_TRIGGER_DESC:
    'Select 1 or more events you would like to trigger a notification',
  NOTIFICATION_CREATE_NOTIFICATION_PERSON: 'Person',
  NOTIFICATION_CREATE_NOTIFICATION_PERSON_DESCRIPTION:
    'Send an notification when a camera has detected a person',
  NOTIFICATION_CREATE_NOTIFICATION_VEHICLE: 'Vehicle',
  NOTIFICATION_CREATE_NOTIFICATION_VEHICLE_DESCRIPTION:
    'Vehicle Send an notification when a camera has detected a vehicle',
  NOTIFICATION_CREATE_NOTIFICATION_CAMERA: 'Camera',
  NOTIFICATION_CREATE_NOTIFICATION_CAMERA_DESCRIPTION:
    'Camera Status Send a notification when offline, license expiring or fog or defocus is detected.',
  NOTIFICATION_CREATE_NOTIFICATION_LOITERING: 'Loitering',
  NOTIFICATION_CREATE_NOTIFICATION_LOITERING_DESCRIPTION:
    'Send an notification when a camera has detected a person',
  NOTIFICATION_CREATE_NOTIFICATION_TAMPERING: 'Tampering',
  NOTIFICATION_CREATE_NOTIFICATION_TAMPERING_DESCRIPTION:
    'Send an notification when a camera has detected a person',
  NOTIFICATION_CREATE_NOTIFICATION_GEBS:
    'Gunshot, Explosion, Breaking Glass, Screaming',
  NOTIFICATION_CREATE_NOTIFICATION_GEBS_DESCRIPTION:
    'Send an notification when a camera has detected a person',
  NOTIFICATION_CREATE_NOTIFICATION_MOTION: 'All Motion',
  NOTIFICATION_CREATE_NOTIFICATION_MOTION_DESCRIPTION:
    'Recommended for cameras that do no support analytics',
  NOTIFICATION_CREATE_NOTIFICATION_SELECT_DEVICES:
    'Select a location, then select one or more devices.',
  NOTIFICATION_CREATE_NOTIFICATION_SELECTED: 'Selected',
  NOTIFICATION_CREATE_NOTIFICATION_EVENT_NOT_SUPPORTED:
    'Some of the below cameras do not support one or more of your selected event types',
  NOTIFICATION_CREATE_SHEDULED: 'Schedule (Optional)',
  NOTIFICATION_CREATE_SHEDULED_SUB_TITLE:
    'Create a custom schedule for your notification.',
  NOTIFICATION_CREATE_SHEDULED_MESSAGE:
    'Create a Schedule For Your Notification.(Optional)',
  NOTIFICATION_CREATE_BTN: 'Create',
  NOTIFICATION_CREATE_DELETE_BTN: 'Delete',
  NOTIFICATION_CREATE_UPDATE_BTN: 'Update Schedule',
  NOTIFICATION_CREATE_ADD_NEW_SCHEDULED: 'Add New Schedule',
  NOTIFICATION_CREATE_CHOOSE_DAY: 'Choose Day',
  NOTIFICATION_CREATE_CHOOSE_TIME: 'Choose Time',
  NOTIFICATION_CREATE_DEVICE_TIMEZONE: 'Device Timezone: Pacific Time',
  NOTIFICATION_CREATE_SCHEDULED_MESSAGE:
    'Notifications will not be sent outside of this schedule.',
  NOTIFICATION_CREATE_SKIP_BTN: 'Skip',
  NOTIFICATION_CREATE_USER_TEXT: 'User',
  NOTIFICATION_CREATE_USER_MESSAGE:
    'Select 1 or more users to assign this notification.',
  NOTIFICATION_CREATE_NOTIFICATION_TYPE: 'Notification Type',
  NOTIFICATION_CREATE_NOTIFICATION_TYPE_DESC:
    'Select at least 1 way to be notified.',
  NOTIFICATION_CREATE_STEP_SIX_EMAIL: 'Email',
  NOTIFICATION_CREATE_STEP_SIX_EMAIL_DESC:
    'You will receive an email when a notification event has been detected',
  NOTIFICATION_CREATE_STEP_SIX_PUSH: 'Push Notification',
  NOTIFICATION_CREATE_STEP_SIX_PUSH_DESC:
    'You will receive an push notification event to your phone via the app',
  NOTIFICATION_CREATE_STEP_SIX_WEB: 'Web Browser (Default)',
  NOTIFICATION_CREATE_STEP_SIX_WEB_DESC:
    'You will receive an push notification event to your phone via the app',
  EDIT_NOTIFICATION_EVENTS: 'Edit Events',
  EDIT_NOTIFICATION_CAMERAS: 'Edit Cameras',
  EDIT_NOTIFICATION_SCHEDULED: 'Edit Scheduled',
  EDIT_NOTIFICATION_USERS: 'Edit Users',
  EDIT_NOTIFICATION_TYPES: 'Edit Notification Type',
  NOTIFICATION_EDIT_NOTIFICATION: 'Edit Notification',
  DELETE_NOTIFICATION_RULE_MESSAGE:
    'Are you sure you want to delete this notification?',
  NOTIFICATION_MESSAGE_DETECTED_PERSON: 'Person Detected',
  NOTIFICATION_MESSAGE_DETECTED_VEHICLE: 'Vehicle Detected',
  NOTIFICATION_MESSAGE_DETECTED_AUDIO: 'Audio Detected',
  NOTIFICATION_MESSAGE_CAMERA_ONLINE: 'Camera is back online.',
  NOTIFICATION_MESSAGE_CAMERA_OFFLINE: 'Camera is offline.',
  NOTIFICATION_MESSAGE_DETECTED_MOTION: 'Motion Detected.',
  NOTIFICATION_MESSAGE_DETECTED_LOITERING: 'Loitering Detected.',
  NOTIFICATION_MESSAGE_DETECTED_TAMPERING: 'Tampering Detected.',
  LOCATION_TIMEZONE_ORGANIZATION_MESSAGE:
    'We selected a time zone based on the address of your location.',
  AUTO_TIMEZONE_FAIL_MESSAGE:
    'The system is unable to detect a time zone based on the address of your location. Please select your timezone.',
  DEVICE_ADD_TIMEZONE_MESSAGE: 'Time zone is derived from the device location.',
  DEVICE_AND_LOCATION: 'Location & Devices',
  SELECT_LOCATION_TO_SHOW_DEVICE: 'Select a location to show devices.',
  SELECT_LOCATION_TITLE: 'Select Location',
  SELECT_DEVICE: 'Select Device',
  ORGANIZATION_TWO_FACTOR_AUTHENTICATION: 'Enable 2FA',
  NOTIFICATION_RETENTION_PERIOD: 'Notifications Retention Period (Days)',
  ORGANIZATION_CONTACT_DROPDOWN_LABEL: 'Organization Contact',
  REMOVE_ORGANIZATION: 'Remove Organization',
  REMOVE_ORGANIZATION_TEXT:
    'You and your customer organization will be permanently removed from the system. Are you sure you want to remove',
  LOCATION_CONTACT_DROPDOWN_LABEL: 'Location Contact',
  BANDWIDTH_USAGE_BUDGET: 'Bandwidth Usage Budget',
  BANDWIDTH_HELPER_TEXT:
    'Enter the maximum upload bandwidth you wish to establish for this location.',
  BANDWIDTH_MAX_CONTENT_HELPER_TEXT: 'Total Max. Streaming Bandwidth: 60 kbps',
  ORG_DAHSBOARD_MANAGE_ALL_DEVICES: 'Manage',
  ORG_DAHSBOARD_ACCOUNTS: 'Accounts',
  ORG_DASHBOARD_ID: 'Organization ID',
  ORG_ACTIVE_DEVICES: 'Devices Online',
  ORG_DEVICE_FIRMWARE_UPTO_DATE: 'All device firmwares are up to date.',
  ORG_LOADING_FIRMWARE_STATUS: 'Loading device firmware status...',
  ORG_FIRMWARE_UPDATES_AVAILABLE: 'Firmware updates available for 54 devices',
  ORG_NO_DEVICES_AVAILABLE: 'No devices available',
  ORG_CLOUD_STORAGE_STATUS_ONE: 'Active',
  ORG_CLOUD_STORAGE_STATUS_TWO: 'Available',
  ORG_ACTIVE_ACCOUNTS: 'Active Accounts',
  ORG_INACTIVE_ACCOUNTS: 'Inactive Accounts',
  ORG_ADMINISTRATORS: 'Administrators',
  ORG_SUSPICIOUS: 'Suspicious',
  ORG_ROLE_CUSTOM_ONE: 'Custom Name',
  ORG_ROLE_CUSTOM_TWO: 'Custom Role 2',
  ORG_CAMERA_AVABILITY: 'Camera Availability (Past 7 Days)',
  ORG_CAMERA_AVABILITY_DETAILS:
    'The percentage of camera online within a one-hour period of time.',
  ORG_REMOVE_PARTNER: 'Remove Partner',
  ORG_VIEW_ALL_ANALYTICS: 'View All Analytics',
  ORG_DASHBOARD_PARTNER_INVITE: 'Invite Partner',
  ORG_REMOVE_PARTNER_DESCRIPTION:
    'All technicians will be removed. Your partner will no longer be able to support your Customer.',
  ORG_REMOVE_PARTNER_MODAL_TITLE: 'Are you sure you want to remove',
  ORG_INVITE_A_PARTNER: 'Invite a Partner',
  ORG_INVITE_PARTNER_MESSAGE:
    'An invitation email will be sent to the partner’s email account entered below. The email must be associated with an admin account.',
  ORG_SEND_PARTNER_INVITE: 'Send Invitation',
  ORG_INVITE_SENT: 'Invitation Sent',
  ORG_INVITE_SENT_MESSAGE:
    'We sent a partner invitation to $email. Your selected partner will be added to your customer once they accept the invitation.',
  ORG_BACK_TO_SETTING: 'Back to Organization Dashboard',
  ORG_EMAIL_ERROR:
    'The email address you entered is not associated with a valid Partner Administrator account. Please try again.',
  ORG_PENDING_INVITATION_ACCEPTENCE: 'Pending invitation acceptance.',
  ORG_LABELS_ROLES: 'Roles',
  ORG_LABELS_ACTIVATED: 'Active',
  ORG_LABELS_INACTIVE: 'Inactive',
  ORG_LABELS_INVITED: 'Invited',
  ORG_LABLES_LOCKED: 'Locked',
  ORG_OFFICE_ACCESS: 'Office Access',
  CAMERA_DETAIL: 'Camera Detail',
  MOBILE_BROWSER_PAGE_TITLE: 'Duclo Connect',
  MOBILE_BROWSER_PAGE_SUB_TITLE:
    'Download our app for a seamless mobile experience.',
  MOBILE_BROWSER_PAGE_MESSAGE:
    'Or, you can access our web app from your desktop or laptop using your favorite browser.',
  MOBILE_BROWSER_PAGE_NEED_HELP_LABEL: 'Need Help ?',
  PARTNER_INVITE_ACCEPTED_TITLE: 'Invitation Accepted',
  PARTNER_INVITE_ACCEPTED_SUB_TITLE:
    'has been added to your list of Customers.',
  PARTNER_INVITE_REJECTED_TITLE: 'Invitation Rejected',
  PARTNER_INVITE_REJECTED_SUB_TITLE:
    'has not been added to your list of Customers.',
  PARTNER_INVITE_BTN_LABEL: 'Go to Customers.',
  ADD_DEVICE_MANUFRACTURE_NAME: 'HANWHA VISION',
  ADD_DEVICE_SCAN_QR_CODE: 'Scan QR Code',
  ADD_DEVICE_MANUAL: 'Manual Entry',
  DEVICE_NAME_REQUIRED_ERROR_MESSAGE: '*Device Name is required',
  APPS_SELECTION_ERROR_MESSAGE: "Select at least one application",
  DEVICE_NAME_LENGTH_ERROR_MESSAGE: '*Max limit reached',
  APPS_SELECTION_ERROR_MESSAGE: "Select at least one application",
  DEVICES_SECOND_STEP_SUBMIT_BUTTON_TEXT: 'Add Device',
  DEVICE_NOT_CLAIMED_TEXT: 'Could Not Claim Device',
  DEVICE_NOT_CLAIMED_STATUS_MESSAGE:
    'The system was unable to claim the device at this time. To claim the device at a later time please “Claim Device” under the Devices section.',
  DEVICES_CHANNELS: 'Channels',
  DEVICES_ASSIGN_LOCATION_TEXT: 'Assign Location',
  DISCOVERED_DEVICES_MODEL_HEADER: 'Setup Discovered Devices',
  COMPLETE_TEXT: 'Complete',
  DISCOVERED_DEVICES_SCAN_BUTTON_TEXT: 'Scan Network',
  DISCOVERED_ONVIF_DEVICES_TEXT:
    'We are scanning your network for compatible devices',
  USERNAME_ERROR_MESSAGE: 'Username is required',
  USERNAME_TEXT: 'Username',
  PASSWORD_TEXT: 'Password',
  APPLY_CREDENTIAL_FOR_ALL_DEVICES_TEXT:
    'Save and apply credentials to detected devices',
  AUTHENTICATE_DEVICE_BUTTON_TEXT: 'Authenticate Device',
  ADD_AND_CLAIM_DEVICE_BUTTON_TEXT: 'Add Device',
  DEVICES_ASSIGN_DEVICE_AREA_TEXT: 'Assign Device Area',
  DEVICES_UPDATE_DEVICE_BUTTON_TEXT: 'Update Device',
  DEVICES_REACHED_MAX_LIMIT:
    'Limit reached at ${count} devices. Please remove a device to add another.',
  MOVE_DEVICE_TO_BUTTON_TEXT: 'Move Device To ',
  DEVICES_ALREADY_ASSIGNED_TEXT:
    'This device has already been assigned to device ${deviceId}.',
  DEVICES_NOT_FOUND_TEXT:
    'We couldn’t find any compatible devices at this time.',
  AUTHENTICATED_STATUS: 'Authenticated',
  ADDED_CLAIMED_STATUS: 'Added and Claimed',
  SERIAL_NUMBER_LABEL: 'Serial Number:',
  PORT_LABEL: 'Port:',
  IP_LABEL: 'IP:',
  PARTNER_INVITE_EXPIRED_TITLE: 'Invitation Expired',
  PARTNER_INVITE_PROCESSED_TITLE: 'This invitation has already been processed.',
  PARTNER_INVITE_INVALID_TITLE: 'Invalid Account',
  REMOVE_ORG_HELPER_TEXT:
    'There are active accounts and/or devices associated with your organization. Please remove all accounts and devices before removing your organization.',
  ACTIVE_LICENSE_HELPER_MESSAGE:
    'Your organization has licenses that have remaining days of service. By removing your organization, these licenses will be removed from the system and can no longer be used.',
  LOCKED_ACCOUNT_TEST: 'Account Locked',
  UNLOCK_ACCOUNT_BUTTON_TEXT: 'Unlock Account',
  ACCOUNT_UNLOCKED: 'Account Unlocked',
  BACK_TO_TECH_BUTTON_TEXT: 'Back to Techs',
  BACK_TO_USERS_BUTTON_TEXT: 'Back to Users',
  ADD_DEVICE_MANUFRACTURE_NAME_TECHWIN: 'HANWHA TECHWIN',
  PROJECT_PAGE_TITLE_DUCLO: 'Duclo - VMS App',
  PROJECT_PAGE_TITLE_MEGATRON: 'Hanwha Vision',
  PAUSE_NOTIFICATION_BTN_LABEL: 'Pause All Notification',
  RESUME_NOTIFICATION_BTN_LABEL: 'Resume All Notifications',
  RESUME_NOTIFICATION_TEXT: 'Resume Notifications',
  PAUSE_NOTIFICATION_TEXT: 'Pause Notifications',
  DELETE_NOTICATION_MENU: 'Delete Notification',
  SHARED_TEXT: 'Shared',
  PAUSED_TEXT: 'Paused',
  DEVICES_TAB_NETWORK_RECORD_EVENTS: 'Record Only Events',
  DEVICES_TAB_MAXIMUM_STREAMING_BITRATE_TITLE: 'Maximum Streaming Bitrate',
  DEVICES_TAB_MAXIMUM_STREAMING_BITRATE_TEXT:
    'You can set up to 3 bitrates (optional). When the camera switches to a new maximum streaming bitrate, the camera will reboot, resulting in a brief loss of video recording and interruption in live streaming.',
  DEVICES_TAB_MAXIMUM_STREAMING_BITRAT_SET: 'Set a Maximum Streaming Bitrate',
  DEVICES_TAB_NETWORK_MAXIMUM_STREAMING_MODAL_TITLE:
    'Maximum Streaming Bitrate',
  DEVICES_TAB_NETWORK_MAXIMUM_STREAMING_MODAL_CONTENT:
    'By setting a maximum bitrate, camera resolution may be impacted.',
  DEVICES_TAB_NETWORK_MAXIMUM_STREAMING_MODAL__SUB_CONTENT:
    'When the camera switches to a new maximum streaming bitrate, the camera will reboot, resulting in a brief loss of video recording and interruption in live streaming.',
  DEVICES_TAB_NETWORK_MAXIMUM_STREAMING_BITRATE_MODAL_SUB_CONTENT:
    'Maximum Streaming Bitrate (kbps)',
  DEVICES_TAB_NETWORK_MAXIMUM_STREAMING_BITRATE_MODAL_DAY: 'Choose a Day',
  CHOOSE_TIME_RANGE: 'Choose a Time Range',
  CHOOSE_TIME_RANGE_SUB_CONTENT: 'Location Time Zone: Pacific Time',
  DEVICES_TAB_NETWORK_MAXIMUM_ADD: 'Add',
  DEVICES_TAB_NETWORK_MAXIMUM_DELETE: 'Delete',
  DEVICES_TAB_MAXIMUM_STREAMING_BITRAT_LD: 'LD',
  DEVICES_TAB_MAXIMUM_STREAMING_BITRAT_SD: 'SD',
  DEVICES_TAB_MAXIMUM_STREAMING_BITRAT_HD: 'HD',
  DEVICES_TAB_MAXIMUM_STREAMING_BITRAT_USD: 'USD',
  LANDING_PAGE_TITLE: 'Home',
  LANDING_PAGE_WELCOME_MSG: 'Hello!     Hanwha Vision Cloud',
  LANDING_PAGE_DESCRIPTION: 'Manage your security surveillance needs here.',
  PARTNER_REGISTER_HEADING: 'Become A Partner',
  PARTNER_REGISTER_SUB_HEADING: 'Tell Us About Your Business',
  COMPLETE_FORM_BELOW_TO_JOIN:'Complete form below to join Hanwha Vision Cloud',
  PARTNER_REGISTER_FORM_INFO_HEADING:
    'Apply to become a Duclo partner and experience the many benefits of our partner program. Once received, You will be contacted by one of our Channel account representatives within 24 hours.',
  PARTNER_THANK_YOU_PAGE_HEADING: 'Thank you.',
  PARTNER_THANK_YOU_PAGE_SUB_HEADING:
    'You’ve Submitted Your Partner Application',
  PARTNER_THANK_YOU_PAGE_FIELDS_INFO_HEADING:
    'Our team is reviewing your application and will contact within 24 hours.',
  CUSTOMER_REGISTER_HEADING: 'Register an account',
  CUSTOMER_REGISTER_SUB_HEADING: 'Tell Us About Your Organization',
  CUSTOMER_REGISTER_FORM_INFO_HEADING:
  'Create a new account for your organization to access Hanwha Vision Cloud service.',
  CUSTOMER_THANK_YOU_PAGE_HEADING: 'Thank you for your registration.',
  CUSTOMER_THANK_YOU_PAGE_SUB_HEADING:
    'Your organization and administrator accounts have been created.',
  CUSTOMER_THANK_YOU_PAGE_FIELDS_INFO_HEADING:
    'We sent you an email with instructions to activate your account.',
  CUSTOMER_THANK_YOU_PAGE_ORGANIZATION_PROFILE_HEADING: 'Organization Profile',
  FIRST_NAME_FIELD_LABEL: 'First Name',
  LAST_NAME_FIELD_LABEL: 'Last Name',
  PHONE_NUMBER_FIELD_LABEL: 'Phone Number',
  EMAIL_ADDRESS_FIELD_LABEL: 'Email',
  BUSSINESS_EMAIL_ADDRESS_FIELD_LABEL: 'Business Email',
  BUSSINESS_NAME_FIELD_LABEL: 'Business Name',
  BUSSINESS_ADDRESS_FIELD_LABEL: 'Business Address',
  BUSSINESS_PHONE_FIELD_LABEL: 'Business Phone',
  BUSSINESS_CONTACT_FIELD_LABEL: 'Business Contact',
  BUSSINESS_WEBSITE_NAME_FIELD_LABEL: 'Business Website',
  BUSSINESS_LINKEDIN_PROFILE_NAME_FIELD_LABEL: 'LinkedIn Page',
  BUSSINESS_LINKEDIN_PAGE_FIELD_LABEL: 'Business LinkedIn Page',
  ORGANIZATION_NAME_FIELD_LABEL: 'Organization Name',
  ORGANIZATION_ADDRESS_FIELD_LABEL: 'Organization Address',
  ORGANIZATION_PHONE_FIELD_LABEL: 'Organization Phone Number',
  ORGANIZATION_WEBSITE_NAME_FIELD_LABEL: 'Organization Website',
  ORGANIZATION_LINKEDIN_PROFILE_NAME_FIELD_LABEL: 'Organization LinkedIn Page',
  SUBMIT_BUTTON_LABEL: 'Submit',
  ADMINISTRATOR_NAME_LABEL: 'Administrator Name',
  NAME_REQUIRED_ERROR_MESSAGE: '*Name is required',
  STEP_PARTNER_REQUIRED_ERROR_MESSAGE: '*STEP Partner Code is required',
  FIRST_NAME_REQUIRED_ERROR_MESSAGE: '*First Name is required',
  LAST_NAME_REQUIRED_ERROR_MESSAGE: '*Last Name is required',
  CANT_LONGER_ERROR_MESSAGE: "*This field can't be longer than 32 characters",
  ONLY_ALPHABETS_ERROR_MESSAGE: 'Enter alphabets only',
  ONLY_ALPHA_NUMBERIC_ERROR_MESSAGE: 'Enter alpha numeric only',
  EMAIL_REQUIRED_ERROR_MESSAGE: '*Email is required',
  EMAIL_REQUIRED_CANT_LESS_ERROR_MESSAGE:
    '*Email must be less than 100 characters',
  WEBSITE_URL_REQUIRED_ERROR_MESSAGE: '*Website URL is required',
  WEBSITE_CORRECT_URL_MESSAGE: '*Enter correct URL',
  ADDRESS_REQUIRED_ERROR_MESSAGE: '*Address is required',
  ADDRESS_FIELD_ERROR_MESSAGE:
    '*Please enter a complete address. Street, City, State, and ZipCode.',
  VALID_PHONE_NUMBER_ERROR_MESSAGE:
    "*Please enter a valid phone number and can't longer than 10 digits",
  FORM_CHECK_TERMS_CONTENT:
    'By checking this box, I acknowledge, and agree to the',
  FOOTER_TERMS_CONDITION_LINK_TEXT: 'Terms of Service',
  FOOTER_PRIVACY_POLICY_LINK_TEXT: 'Privacy Policy',
  CAPTCHA_ERROR_MESSAGE:
    'Not able to get the captcha data, please refresh the page and try again.',
  BACK_TO_HOME_BUTTON_TITLE: 'Back To Home',
  ACTIVATE_YOUR_ACCOUNT_BUTTON_TITLE: 'Activate your account',
  HELP: 'Help',
  CUSTOMERS: 'Customers',
  CLOUDPORTAL: 'Cloud Portal',
  DMPRO: 'DM Pro',
  SIGHTMIND: 'SightMind',
  FLEXAI: 'Flex AI',
  ONCLOUD: 'On Cloud',
  PHOENIX: 'Phoenix',
  TOP_HEADER_PARTNER_USERS_NAV_TITLE: 'STEP Partner Users',
  TOP_HEADER_CLOUD_PORTAL_NAV_TITLE: 'Cloud Portal',
  DEVICES_REMOVE_DEVICE: 'Remove',
  NEW_USER_SELECT_ROLE_DROPDOWN_DEFAULT_LABEL: 'Select Role',
  ADMIN_USER_CUSTOM_ROLE_LABEL: 'Administrator',
  SUPER_ADMIN_ROLE_LABEL: 'Super Admin',
  ADMIN_ROLE_LABEL: 'Admin',
  LICENSES_PAGE_TITLE: 'Licenses',
  ADD_LICENSE_ORDERNO_REQUIRED: '*Order Number is required',
  ADD_LICENSE_INVALID_MESSAGE: 'Invalid order number, license key combination',
  LICENSE_ADD_LICENSE_TITLE: 'Add License',
  LICENSE_STEP_ONE_OF_TWO: 'Step 1 of 2',
  LICENSE_ORDER_NUMBER: 'Order Number',
  LICENSE_KEYS_TEXT: 'Enter License Keys',
  LICENSES_ENTER_TEXT: 'Enter Licenses Manually',
  LICENSES_ENTER_BY_ORDER_TEXT: 'Add Licenses by Order Number',
  LICENSES_VALIDATION_FAIL_ERROR: 'Validation failed for requested Order Number and License Keys',
  LICENSE_ORDER_NO_TEXT: 'Order Number',
  LICENSE_KEYS_TEXT_PLACEHOLDER: 'License Keys',
  LICENSE_TAB_LICENSE_INFO: 'Add Licenses',
  LICENSE_ENTER_KEY_REQUIRED:
    'Enter the order number, then press “Enter” or “Return” to continue.',
  LICENSE_INVALID_ORDER_NUMBER: 'Oops! It seems the order you requested is invalid. Could you please provide a valid order and try once more?',
  LICENSE_ORDER_NUMBER_DOES_NOT_EXIST:
    'The order number you entered does not exist.',
  LICENSE_ORDER_LICENSES_ALREADY_ADDED:
    'Licenses for this order have already been added to the system.',
  LICENSE_SECOND_STEP_SUBMIT_BUTTON_TEXT: 'Confirm',
  LICENSE_OTP_INVALID: "Invalid otp code.",
  LICENSE_DID_NOT_GET_CODE_TEXT: "Didn't get the code?",
  LICENSE_CONF_CODE_SENT_TEXT: "We've sent your confirmation code to the email address",
  LICENSE_CONF_CODE_ENTER_TEXT: "Please enter the code to confirm",
  LICENSE_RESEND_CODE_TEXT: 'Click to resend',
  LICENSE_CONTINUE_TEXT: 'Continue',
  LICENSE_STEP_TWO_OF_TWO: 'Step 2 of 2',
  LICENSE_CONF_CODE_INVALID: 'Confirmation code must be a single digit',
  LICENSE_SUCCESSFULLY_ADDED: 'Successfully added',
  LICENSE_SUCCESSFULLY_LICENSE_TO: 'licenses to',
  LICENSE_CONF_CODE_REQUIRED: 'Confirmation code is required',
  CHANNELS_NETWORK_EDGE_FIRMWARE_UPDATE_TITLE:
  'Cloud Connector Firmware Update',
  CHANNELS_INFO_NETWORK_DEVICE_MANAGEMENT_TITLE: 'Device Management',
  CHANNELS_INFO_UPDATE_NOW_TITLE: 'Update Now',
  CHANNELS_NETWORK_DEVICE_FIRMWARE_UPDATE_TITLE: 'Device Firmware Update',
  CHANNELS_INFO_DEVICE_NAME_TITLE: 'Device Name',
  CHANNELS_INFO_DEVICE_TITLE: 'Device Information',
  CHANNELS_INFO_CANNOT_AUTH_DEVICE_TITLE: 'Cannot authenticate device.',
  CHANNELS_INFO_CHANNELS_COUNT_TITLE: 'Channels',
  CHANNELS_INFO_NETWORK_TITLE: 'Network',
  CHANNELS_INFO_LOCATION_TEXT: 'Assign Location',
  CHANNELS_INFO_DATE_TIME_TITLE: 'Date & Time',
  CHANNELS_INFO_CAMERA_FIRMWARE_TITLE: 'Device Firmware Version',
  CHANNELS_INFO_DM_BRIDGE_TITLE: 'DM Bridge Version',
  CHANNELS_INFO_ERROR: 'Device error.', 
  CHANNELS_INFO_ERROR_MESSAGE:
  'We have encountered an error establishing a connection to this device.', 
  DEVICE_INFO_DEVICE_NAME_TITLE: 'Device Name',
  DEVICES_LIST_LOCATION: 'Location',
  DEVICES_LIST_AREA: 'Area',
  DEVICES_LIST_SERIAL_NUMBER: 'Serial Number',
  DEVICES_LIST_LICENSE: 'License',
  DEVICES_LIST_ATTACH_LICENSE: 'Attach License',
  DEVICES_LIST_STATUS: 'Status',
  CHANNELS_INFO_CANNOT_AUTH_DEVICE_CONTENT:
  'Please go to the Duclo Connect camera webpage to update the device username and password.',
  SCAN_NETWORK_FOR_DEVICE_BUTTON: 'Scan Network for Device',
  DEVICES_LIST_APPLICATION_LINKED: 'Applications Linked',
  DEVICES_LIST_APPLICATION_ACTIVATE:'Activate',
  DEVICES_LIST_APPLICATION_ACTIVATE_SUB_TITLE: 'This will activate an available license for',
  DEVICES_LIST_APPLICATION_ACTIVATE_SUB_TITLE_TWO: 'Are you sure you want to proceed?',
  DEVICES_LIST_APPLICATION_ACTIVATE_YES: 'Yes',
  DEVICES_LIST_APPLICATION_ACTIVATE_CANCEL: 'Cancel',
  DEVICES_LIST_APPLICATION_DEACTIVE:'Deactivate',
  DEVICES_LIST_APPLICATION_DEACTIVATE_SUB_TITLE: 'This will remove the license from',
  DEVICES_LIST_APPLICATION_DEACTIVATE_SUB_TITLE_TWO: 'Are you sure you want to proceed?',
  DEVICES_LIST_APPLICATION_ACTIVATE_REMOVE:' Remove App',
  DEVICES_LIST_APPLICATION_APP_TYPE_ONCLOUD:'oncloud',
  DEVICES_LIST_APPLICATION_APP_TYPE_DMPRO:'dmpro',
  DEVICES_LIST_APPLICATION_APP_TYPE_FLEXAI:'flexai',
  DEVICES_LIST_APPLICATION_APP_TYPE_VMS: 'vms',
  DEVICES_LIST_APPLICATION_APP_TYPE_SIGHTMIND: 'sightmind',
  PENDING_CLAIMED_STATUS: ' Pending Claim',
  DEVICES_ADDED_TEXT: ' Devices Added',
  LIVE_VIEW_TYPE: 'LIVE_VIEW',
  UPDATE_FIRMWARE_DEVICE_MANUAL: 'manual',
  CHANNELS_INFO_UPDATE_AVAILABLE: 'Update available',
  TIMEZONE_REQUIRED_ERROR_MESSAGE: '*Timezone is required',
  BREADCRUMB_BACK:'back',
  DEVICE_LIST_ATTACH_ALL_LINCESES:'Attach All Licenses',
  ORGANIZATION_EDIT: 'Edit Organization',
  ORGANIZATION_LDAP_AUTHENTICATION: 'Authenticate Using External LDAP Service',
  ORGANIZATION_LDAP_STEP_1_OF_2: 'Step 1 of 2',
  ORGANIZATION_LDAP_CONNECTION_URL: 'Connect URL',
  ORGANIZATION_LDAP_USER_DN: 'User DN',
  ORGANIZATION_LDAP_USER_DN_HELPER: 'Please enter with the below format: cn=*,ou=people,dc=MyCompanyName,dc=com',
  ORGANIZATION_LDAP_TEST_CONNECTION: 'Test Connection',
  ORGANIZATION_LDAP_SUCCESS_TEXT1: 'Successfully connected to LDAP Service',
  ORGANIZATION_LDAP_SUCCESS_TEXT2: 'By clicking “Continue”, you will not be able to change your Bind DN and Bind Credential.',
  ORGANIZATION_LDAP_CONTINUE: 'Continue',
  ORGANIZATION_LDAP_CANCEL: 'Cancel',
  ORGANIZATION_LDAP_CONFIRM: 'Confirm',
  ORGANIZATION_LDAP_TRY_AGAIN: 'Try Again',
  ORGANIZATION_LDAP_STEP_2_OF_2: 'Step 2 of 2',
  ORGANIZATION_LDAP_MAP_ACCOUNT: 'Map Account Attributes',
  ORGANIZATION_LDAP_SAVE: 'Save',
  ORGANIZATION_LDAP_GO_BACK: 'Go Back',
  ORGANIZATION_LDAP_SUCCESS: 'Your organization is now configured to authenticate user accounts using your LDAP service.',
  ORGANIZATION_LDAP_COMPLETE: 'Complete',
  ORGANIZATION_LDAP_UNABLE: 'Unable to authenticate.',
  ORGANIZATION_LDAP_UNABLE_TRY_AGAIN: 'Please try again.',
  ORGANIZATION_LDAP_URL_REQUIRED: '*URL is required',
  ORGANIZATION_LDAP_BIND_DN_REQUIRED: '*Bind DN is required',
  ORGANIZATION_LDAP_BIND_CREDENTIAL_REQUIRED: '*Bind Credentials is required',
  ORGANIZATION_LDAP_USER_DN_REQUIRED: '*User DN is required',
  ORGANIZATION_LDAP_SETTINGS: 'LDAP Settings',
  ORGANIZATION_LDAP_SETTINGS_UPDATE1: 'Are you sure you want to update the attribute mappings?',
  ORGANIZATION_LDAP_SETTINGS_UPDATE2: 'The attributes of existing users will be updated.',
  ORGANIZATION_LDAP_SETTINGS_URL: 'URL',
  ORGANIZATION_LDAP_SETTINGS_BIND_DN: 'Bind DN',
  ORGANIZATION_LDAP_SETTINGS_BIND_CREDENTIALS: 'Bind Credentials',
  ORGANIZATION_LDAP_SETTINGS_FIRST_NAME: 'First Name',
  ORGANIZATION_LDAP_SETTINGS_LAST_NAME: 'Last Name',
  ORGANIZATION_LDAP_SETTINGS_EMAIL: 'Email',
  ORGANIZATION_LDAP_SETTINGS_PHONE: 'Phone Number (optional)',
  ORGANIZATION_LDAP_SETTINGS_PP: 'Profile Picture (optional)',
  MANAGE_ORG_PAGE_ORG_TITLE: 'Organization',
  CUSTOMER_NAME_REQUIRED_ERROR_MESSAGE: '*Please enter the customer’s name.',
  GOT_NEW_CUSTOMER: 'Got a new customer?',
  ARE_YOU_EXISTING_STEP_PARTNER:'Are you an existing STEP Partner?',
  JOIN_AS_A_STEP_PARTNER:'Join as a STEP Partner',
  BECOME_PARTNER: 'Become a Partner',
  DISCOVERED_SETUP_DEVICE:'Setup Device',
  DEVICE_LIST_MANUFACTURE:'Manufacture',
  DEVICES_LIST_STATUS_TITLE: 'Device Status',
  FLEXAI_PROD_LABEL: 'FLEX AI PROD',
  TIME_ZONE_TITLE_PLACEHOLDER: 'Time Zone',
  BETA_AGREEMENT_TITLE: 'Beta Agreement',
  LOADING: 'Loading...',
  UPDATE_DEVICE_FIRMWARE: 'Update Device Firmware',
  CHANNELS_INFO_UPDATE_DEVICE_FW_MESSAGE:
    'Update available. Your device firmware will be updated based on the day and time specified below.',
  RESET_PASSWORD_USER_MODAL_TITLE: 'Reset Password Email Sent!',
  RELEASE_VERSION_TITLE: 'Version:',
  TEXT_COPIED_TITLE: 'Copied!',
  DASHBOARD_TEXT_COPIED_TITLE :'Copied',
  AUTHENTICATE_INVALID: "Invalid Credential",
  STEP_PARTNER_ID: 'STEP Partner Code',
  TEXT_COPIED_ORGID_TITLE: 'Copy Org ID',
  TEXT_COPIED_ORGNAME_TITLE: 'Copy Short Name',
  TEXT_ORGID_SHORTNAME_TITLE: 'Organization ID & Short name',
  STEP_PARTNER_ID: 'STEP Partner Code',
  SALES_PERSON_NAME: 'Sales Person Name',

  DOWNLOAD_BTN_TEXT: 'Download Log',
  DOWNLOAD_MODAL_TITLE_TEXT: 'Download Logs',
  DOWNLOAD_MODAL_DESCRIPTION_TEXT:
    'To download device logs,please provide an encryption password. You will use this password to decrypt the logs later.',
  DOWNLOAD_ENCRYPTION: 'Encryption',
  DOWNLOAD_ENCRYPTION_PLACEHOLDER: 'Enter encryption key',
  DOWNLOAD_CANCEL: 'Cancel',
  DOWNLOAD_START: 'Download',
  DoWNLOAD_ERROR:'Download Error',
  DOWNLOAD_ERROR_MEESAGE: 'Something went wrong!. Please try again later.',
  TOP_HEADER_UPDATE_PASSWORD_NAV_TITLE: 'Update Password',
  UPDATE_PASSWORD_PAGE_TITLE: 'Update Password',
  NEW_PASSWORD_FIELD: 'New Password',
  CURRENT_PASSWORD:'Current Password',
  PASSWORD_REQUIREMENTS:'Password Requirements:',
  AT_LEAST_8_CHAR:'At least 8 characters',
  ONE_UPPER_CASE_LETTER:'At least one upper case letter',
  ONE_LOWER_CASE_LETTER:'At least one lower case letter',
  AT_LEAST_ONE_NUMBER:'At least one number (0–9)',
  ONE_SPECIAL_CHAR:'At least one special character (#$%^&*@!)',
  CURRENT_PASSWORD_REQUIRED:'Current password is required',
  NEW_PASSWORD_REQUIRED:'New password is required',
  PASSWORD_SHOULD_NOT_BE_SAME:'Current password and new password should not be same',
  PASSWORD_MUST_EIGHT_CHAR:'Password must be at least 8 characters',
  MUST_ONE_UPPER_LETTER:'Must contain at least one uppercase letter',
  MUST_ONE_LOWER_LETTER:'Must contain at least one lowercase letter',
  MUST_ONE_NUMBER:'Must contain at least one number',
  MUST_ONE_SPECIAL_CHAR:'Must contain at least one special character',
  PASSWORD_MUST_MATCH:'Passwords must match',
  CONFIRM_PASSWORD_REQUIRED:'Confirm password is required',
  STEP_PARTNER_CODE:'STEP Partner Code',
  SALES_PERSON_NAME:'Sales Person Name',
  APPLICATION_DETAIL:'Application Details',
  SHORT_NAME_MIN_LENGTH_ERROR_MESSAGE :"Organization Short Name must be at least 6 characters",
  SHORT_NAME_MAX_LENGTH_ERROR_MESSAGE:"Organization Short Name must be at most 12 characters",
  SHORT_NAME_NO_SPECIAL_CHARACTERS_ERROR_MESSAGE:"No special characters allowed",
  ORG_DASHBOARD_ID_AND_SHORTNAME: 'Organization ID & Short Name',
  ORG_SHORT_NAME:'Organization Short Name',
  SHORT_NAME_REQUIRED_ERROR_MESSAGE :'Organization Short Name is a required',
  ORG_SHORT_EXISTS :'This Short Name has already been taken',
  ORG_SHORT_NAME_HINT : 'Your Organization Short Name must be between 6 and 12 characters and contain no \nspecial characters. Organization Short Name cannot be updated after submission.',
  SESSION_OUT_MESSAGE: 'By enabling session timeout, your session will be logged out after 30 minutes of inactivity.',
  SESSION_OUT_TITLE: 'Session Timeout',
  UPDATE_TERM_OF_SERVICES:'Updates to Terms of Services',
  TO_CONTINUE_PLEASE_ACKOWLEDGE:'To continue, please acknowledge that you have read the updated',
  TERM_OF_SERVICES:'Terms of Services.',
  BY_CHECKING_THISBOX :'By checking this box, I agree to the updated',
  UPDATE_PRIVACY_POLICY:'Updates to Privacy Policy',
  PRIVACY_POLICY:'Privacy Policy.',
  TOS_PP_CONTINUE:'Continue',
  TOS_PP_CANCEL:'Cancel',
  UPDATE_TERM_SERVICE_POLICY:'Updates to Terms of Services and Privacy Policy',
  TERM_OF_SERVICES_NODOT:'Terms of Services',
  DEVICE_STATUS_CLAIMED: 'CLAIMED',
  DEVICE_NAME_MAX_LENGTH_MESSAGE: 'Maximum length is 40 characters',
  UPDATE_CLOUD_CONNECT_FIRMWARE_TITLE: "Update Cloud Connect Firmware",
  UPDATE_DEVICE_FIRMWARE_TITLE_INFO: "By continuing, the camera will remain offline until the firmware update is complete.",
  UPDATE_FIRMWARE_BUTTON_LABEL: "Update Firmware",
  UPDATE_FIRMWARE_CANCEL_BUTTON_LABEL: "Cancel",
  AREA_NAME_ALREDAY_EXISY:"This area name already exists",
  DEVICES_EXPIRED_DEVICE_STATUS: 'EXPIRED',
  DEVICES_EXPIRED_LABEL_DEVICE_STATUS: 'Expired',
  DEVICES_REVIEW_EXPIRED_LICENSES_DISMISS_BUTTON_TITLE: 'Dismiss',
  DEVICE_REVIEW_EXPIRE_LICENSES_DEVICE_MODAL_BUTTON_TITLE: 'Review Expired License(s)',
  DEVICE_LICESNSE_EXPIRE_SUBTITLE: 'To avoid a disruption in service, you must renew your service for the camera(s) by attach a new license.',
  DEVICE_EXPIRED_LICENSE_CONTENT:
    'You have one or more licenses that are expired.',
  DEVICES_LIST_LICENSE_REMAINING_STATUS: 'License Remaining',
  LICENSE_ONBOARDING_STATUS: 'ONBOARDING',
};

export default constants;
